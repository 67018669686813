const thirdSecData = [
  //   {
  //     id: 1,
  //     logo: "./image1.png",
  //   },
  //   {
  //     id: 2,
  //     logo: "./image2.png",
  //   },
  //   {
  //     id: 3,
  //     logo: "./image3.png",
  //   },
  //   {
  //     id: 4,
  //     logo: "./image4.png",
  //   },
  {
    id: 5,
    logo: "./image5.png",
  },
  {
    id: 6,
    logo: "./image6.png",
  },
  {
    id: 7,
    logo: "./image7.png",
  },
  {
    id: 8,
    logo: "./image8.png",
  },
  {
    id: 9,
    logo: "./image9.png",
  },
  {
    id: 10,
    logo: "./image10.png",
  },
  {
    id: 11,
    logo: "./image11.png",
  },
  {
    id: 12,
    logo: "./image12.png",
  },
  /*   {
    id: 13,
    logo: "./image13.png",
  }, */
]

export default thirdSecData
