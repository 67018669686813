import "./ClientBoard.scss"
import React, { useState } from "react"
import ClientBoardHeader from "../../headers/ClientBoardHeader"
import ClientBoardBody from "./ClientBoardBody"
import {useLocation} from 'react-router-dom';

import ClientBoardSecondHeader from "../../headers/ClientBoardSecondHeader"

function ClientBoard(props) {
  const location = useLocation();
  const {carercom}=location.state;
  console.log('in clientboard***********');
 
  const [filter, setFilter]=useState('');
  return (
    <div className="ClientBoard">
      <div className="innerClientBoard">
        <ClientBoardHeader />
        
        <div className="ClientBoardSecondHeader">
          <div className="innerClientBoardSecondHeader">
            <ClientBoardSecondHeader setFilter={setFilter} />
            <ClientBoardBody  carercom={carercom} filter={filter}  />
          </div>
      </div>
      </div>
    </div>
  )
}

export default ClientBoard
