import React from "react"
import "./Dashboard.scss"
import DashboardHeader from "../headers/DashboardHeader"
import ClientBoardHeader from "../headers/ClientBoardHeader"
import DashboardSidebar from "../sidebars/DashboardSidebar"



const  ClientCare=()=> {
  return (
    <main className="dashBoard">
      <div className="innerMain">
        <ClientBoardHeader />
        <DashboardHeader />
        <div className="sideBarAndRightBar">
          <DashboardSidebar />
          <div className="RightMainPanel">
            <div>
                <div className="header">
                <p className="headerText">First-time Assessment</p>
                 </div>
                
                <div className="partLinkWrap boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Personal Care</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Everyday Activities</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Social Support</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Environmental</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Nutrition and hydration</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Medical</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Administrative</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Psychological</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="header">
                <p className="headerText">Additional Assessments</p>
                 </div>
                 <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Behaviour</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Communication</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Condition specific</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Control of Substances Hazardous to Health (COSHH)</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Virus related Infection (like COVID)</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Dysphagia</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">End of life</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Environment anf fire</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Financial</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Medication</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Mental capacity</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Moving and handling</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Restrictive pratice</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Seizure</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Waterloo</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="header">
                <p className="headerText">Documents</p>
                 </div>
                 <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Upload Documents</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Download Documents</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="partLinkWrap  boxline">
                    <div className="theWrap">
                        <h1 className="subTitleHead">Signature</h1>
                        <p className="pupulateDesc "></p>
                    </div>
                    <div className="addDetlsOrEdit">
                        <div className="plusIconAndAddDtlsTxt" smooth >
                        <p className="addDetlsTxt">Update Assessment</p>
                        </div>
                    </div>
                </div>
            </div>
            
          
          </div>
        </div>
      </div>
    </main>
  )
}

export default ClientCare