import "./DashboardSidebar.scss"
import React from "react"
import { Link } from "react-router-dom"
import user from "../assets/user-svgrepo-com.svg"
import accPix from "../assets/sectionSection/ashkan-forouzani-DPEPYPBZpB8-unsplash.jpg"

function DashboardSidebar(props) {
 const {client} = props;
  return (
    <div className="sideBar">
      <div className="innerDashboardSideBar">
        <ul className="sideBarLinkWrap">
          <li className="sideBarLink">
            <Link className="sideBarLinkk">
              <img src={user} alt="" className="linkImg" />
              About<strong>{client.firstName}</strong> 
            </Link>
          </li>
          <li className="sideBarLink">
            <Link className="sideBarLinkk">
              <img src={user} alt="" className="linkImg" />
              Client plan
            </Link>
          </li>
          <li className="sideBarLink">
            <Link className="sideBarLinkk">
              <img src={user} alt="" className="linkImg" />
              Care plan
            </Link>
          </li>
          <li className="sideBarLink">
            <Link className="sideBarLinkk">
              <img src={user} alt="" className="linkImg" />
              Task planner
            </Link>
          </li>
          <li className="sideBarLink">
            <Link className="sideBarLinkk">
              <img src={user} alt="" className="linkImg" />
              Medication
            </Link>
          </li>
          <li className="sideBarLink">
            <Link className="sideBarLinkk">
              <img src={user} alt="" className="linkImg" />
              Care Team
            </Link>
          </li>
          <li className="sideBarLink">
            <Link className="sideBarLinkk">
              <img src={user} alt="" className="linkImg" />
              Care circle
            </Link>
          </li>
          <li className="sideBarLink">
            <Link className="sideBarLinkk">
              <img src={user} alt="" className="linkImg" />
              Share access
            </Link>
          </li>
          <li className="sideBarLink">
            <Link className="sideBarLinkk">
              <img src={user} alt="" className="linkImg" />
              Settings
            </Link>
          </li>
        </ul>

        <div className="sideBarAccPixAndName">
          <p className="sideBarAccName">{client.firstName} {client.lastName}</p>
          <img src={accPix} alt="" className="accPix" />
        </div>
      </div>
    </div>
  )
}

export default DashboardSidebar
