import "./ClientBoardSecondHeader.scss"
import React from "react"
import searchIcon from "../assets/magnifying-glass.svg"


const ClientBoardSecondHeader =(props)=> {
  const {setFilter}=props;
  return (
    
        <div className="statesAndSearch">
          <form action="" className="form">
            <div className="activeState cmnAtr">
              <label htmlFor="" className="stateLabel">
                Active
              </label>
              <input autoFocus className="checker" type="checkbox" />
            </div>

            <div className="temporarilyInactiveState cmnAtr">
              <label htmlFor="" className="stateLabel">
                Temporarily Inactive
              </label>
              <input autoFocus className="checker" type="checkbox" />
            </div>

            <div className="inActiveState cmnAtr">
              <label htmlFor="" className="stateLabel">
                Inactive
              </label>
              <input autoFocus className="checker" type="checkbox" />
            </div>
          </form>

          <div className="serarchAndCategory">
            <div className="searchWrap">
              <input autoFocus type="search" className="search" onChange={(e)=>setFilter(e.target.value)} />
              <img src={searchIcon} alt="" className="searcIcon"  />
            </div>

            <div className="categoryGrp">
              <select className="selectOpt">
                <option className="opts" value="">
                  Salsford
                </option>
                <option className="opts" value="">
                  Salsford
                </option>
                <option className="opts" value="">
                  Salsford
                </option>
              </select>
            </div>
          </div>
        </div>

       
  )
}

export default ClientBoardSecondHeader
