const secondSecData = [
  {
    id: 1,
    Bg: "./ashkan-forouzani-DPEPYPBZpB8-unsplash.jpg",
    slideText: "Care Management",
    width: 90,
  },

  {
    id: 2,
    Bg: "./cdc-lNkRnZPfiwY-unsplash.jpg",
    slideText: "People & Operations",
    width: 20,
  },
  {
    id: 3,
    Bg: "./pexels-thirdman-7659685.jpg",
    slideText: "Auditing",
    width: 20,
  },
  {
    id: 4,
    Bg: "./pexels-andrea-piacquadio-3768131.jpg",
    slideText: "Connecting Everyone",
    width: 20,
  },
]

export default secondSecData
