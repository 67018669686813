import "./AboutMePanel.scss"
import "../css/main.scss"
import React, { useRef, useState, useEffect } from "react"
import { Link, Route, Routes } from "react-router-dom"
import dropDownIcon from "../assets/angles-down.svg"
import dropUpIcon from "../assets/angles-up.svg"
import plusIcon from "../assets/circle-plus.svg"
import editIcon from "../assets/edit_note.svg"
import mapImage from "../assets/detailed-world-map-29681182.jpg"

import infoImg from "../assets/circle-info.svg"
import choosePic from "../assets/user-svgrepo-com.svg"
import cameraPic from "../assets/camera.svg"
import nextImg from "../assets/angle-right.svg"
import { HashLink } from "react-router-hash-link"
import ButtonSwitch from "../components/buttonSwitch"
import * as Constants from "../Constants";
import RadioOption from "../components/radioOption"
import Phone from "../components/phone"



const find = (event) => {
  event.preventDefault();
  console.log(event.target.value)
  return event.target.value;

};
const PersonalDetailForm =(props)=>{
   const fistnameRef= useRef(null);
  const client=props.client;
  const setClient=props.setClient;
  const value=props.value;
  const setValue= props.setValue;

  props.setFormTitle("Personal Details");
  useEffect(() => {
    console.log("load personal");
    console.log(client);
 
    }, []);
  return (
    <div className="PersonalDetailForm">
      <div className="innerPersonalDetailForm">
        <form action="" className="lsForm">
          <div className="choosePict">
            <div className="chooseImgInn">
              <div className="chooseImgInnneer">
                <img src={choosePic} alt="" className="chooseImgIcon" />
                <Link>
                  <img src={cameraPic} alt="" className="cameraIcon" />
                </Link>
              </div>
              <div className="imgTypeLst">
                <p className="imgTypeTxt">JPG, PNG Max size of 12mb</p>
              </div>
            </div>
          </div>

          <div className="dashBoardProfileSection">
            <h2 class="editInput">Profile</h2>

            <div className="dashboardCurrentStatus">
              <p className="currStatus">Current Status</p>
              <ButtonSwitch yesTitle="Active" noTitle="Inactive" value={client.status=='Active'?1:2} 
              onSelected={(e)=>{let s=e==1?"Active":"Inactive";setClient({...client,status:s})}} />
            </div>

            <div className="labelAndInputs">
              <label htmlFor="" className="dashbLable">
                Title
              </label>
              <div className="options">
                <RadioOption type="radio" title="Mr" checked={client.title=='Mr'?true:false} onClick={()=> setClient({...client,title:'Mr'})} />
                <RadioOption type="radio" title="Mrs" checked={client.title=='Mrs'?true:false} onClick={()=> setClient({...client,title:'Mrs'})} />
                <RadioOption type="radio" title="Miss" checked={client.title=='Miss'?true:false} onClick={()=> setClient({...client,title:'Miss'})}/>
              </div>
            </div>

            <div className="labelAndInputs">
              <label htmlFor="" className="dashbLable">
                First name
              </label>
              <input
                 ref={fistnameRef}  value={client.firstName} onChange={(e)=> setClient({...client,firstName:e.target.value})}  
                required
                type="text"
                className="firstNameInpt"
                placeholder="First Name"
              />
            </div>
            <div className="labelAndInputs">
              <label htmlFor="" className="dashbLable">
                Middle name 
              </label>
              <input
                 value={client.middle} onChange={(e)=> setClient({...client,middle:e.target.value})} 
                required
                type="text" 
                className="firstNameInpt"
                placeholder="Middle Name"
              />
            </div>
            <div className="labelAndInputs">
              <label htmlFor="" className="dashbLable">
                Last name
              </label>
              <input
                
                value={client.lastName} onChange={(e)=> setClient({...client,lastName:e.target.value})}  
                type="text"
                className="firstNameInpt"
                placeholder="Last Name"
              />
            </div>
            <div className="labelAndInputs">
              <label htmlFor="" className="dashbLable">
                Preferred name
              </label>
              <input
                
                value={client.alias} onChange={(e)=> setClient({...client,alias:e.target.value})}  
                type="text"
                className="firstNameInpt"
                placeholder="Preffered Name"
              />
            </div>
            <div className="labelAndInputs">
              <label htmlFor="" className="dashbLable">
                prefers to be referred to as
              </label>
              <div className="options">
              <RadioOption type="radio" title="He/Him" checked={client.title=='He/Him'?true:false} onClick={()=> setClient({...client,title:'He/Him'})} />
              <RadioOption type="radio" title="She/Her" checked={client.title=='She/Her'?true:false} onClick={()=> setClient({...client,title:'She/Her'})} />
              <RadioOption type="radio" title="They/Them" checked={client.title=='They/Them'?true:false} onClick={()=> setClient({...client,title:'They/Them'})} />

               
              </div>
            </div>
            <div className="labelAndInputs">
              <label htmlFor="" className="dashbLable">
                Date of birth
              </label>
              <input
                value={client.dob} onChange={(e)=> setClient({...client,dob:e.target.value})} 
                type="date"
                className="firstNameInpt"
                placeholder="Date of birth"
              />
            </div>
          </div>

          <div className="dashBoardContactDetailsSection">
            <h2 class="editName">Contact details</h2>

            <div className="labelAndInputs">
              <label htmlFor="" className="dashbLable">
                Primary phone number
              </label>
              <Phone value={client.phone1} onSet={(e)=> setClient({...client,phone1:e})}  />
            </div>
            <div className="labelAndInputs">
              <label htmlFor="" className="dashbLable">
                Other phone number
              </label>
             <Phone value={client.phone2} onSet={(e)=> setClient({...client,phone2:e})}  />
            </div>
            <div className="labelAndInputs">
              <label htmlFor="" className="dashbLable">
                Email Address
              </label>
              <input
                value={client.email} onChange={(e)=> setClient({...client,email:e.target.value})} 
                type="email"
                className="firstNameInpt"
                placeholder="Enter your email address"
              />
            </div>
            <div className="labelAndInputs">
              <label htmlFor="" className="dashbLable">
                Address
              </label>
              <div className="dashBrdSearchAddressBtn">
                <button type="button" className="DshBrdSearch">
                  Search for an address
                </button>
              </div>

              <input
                value={client.address} onChange={(e)=> setClient({...client,address:e.target.value})} 
                type="text"
                className="firstNameInpt"
                placeholder="Type in address"
              />
              <div className="mapDiv">
                <img src={mapImage} alt="" className="DashBrdImg" />
              </div>
              <p className="dashBrdImgCaption">
                <img src={infoImg} alt="" className="cautionDshB" /> If this
                isn't the correct location, drag the location icon to the
                correct position.
                <span className="dshBrdCap">
                  Secure check-in zone
                  <img src={infoImg} alt="" className="cautionDshB" />
                </span>
              </p>

              <div className="rangeWrapDsh">
                <div className="dashBrdRange">
                  <span className="rangeText">50</span>
                  <input
                    type="range"
                    name="meters"
                    value={value ? value / 5 : value}
                    onChange={(e) => setValue(e.target.value * 5)}
                    className="meters"
                  />
                  <span className="rangeText">500</span>
                </div>
                <input
                  type="text"
                  name="meters number"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  className="metersNumber"
                />
                <p className="meterChange">meters</p>
              </div>
            </div>
          </div>

          <div className="dashBoardAccessDetlsSection">
            <h2 class="editName">Access Details</h2>

            <div className="labelAndInputs">
              <label htmlFor="" className="dashbLable">
                Access Details
              </label>
              <textarea type="text" className="textAreaDashBrd" value={client.accessInfo} onChange={(e)=> setClient({...client,accessInfo:e.target.value})}  />
            </div>
          </div>

          <div className="dashBoardHighlightsSection">
            <h2 class="editName">Highlights</h2>

            <div className="labelAndInputs">
              <label htmlFor="" className="dashbLable">
                Highlights
              </label>
              <textarea type="text" className="textAreaDashBrd" value={client.highlight} onChange={(e)=> setClient({...client,highlight:e.target.value})} />
            </div>
          </div>

          
        </form>

        <div className="formSidePanel ">
          <p className="formSidePanelTitle">Personal Details</p>
          <ul className="formSidePanelLists">
            <li className="formSidePanelLink">
              <Link className="fomLink">Profle</Link>
            </li>
            <li className="formSidePanelLink">
              <Link className="fomLink">Contact Details</Link>
            </li>
            <li className="formSidePanelLink">
              <Link className="fomLink">Highlights</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>)
}
const EditForm =(props)=>{
  const {onCancel}=props;
  const {onNext} =props;
  const {onSave} =props;
  var {title}=props;
  return (<div className="EditForm">
  <div className="innerEditForm">
   

    <footer className="dashBrdFt">
      <div className="innerDashbrdft">
        <button type="button" className="cancelBtn" onClick={onCancel}>
          <span className="cancleBtnn">
            Cancel
          </span>
        </button>
        <div className="nextFromSection" onClick={onNext}>
          <p className="nextTabDshBrd">{title}</p>
          <img src={nextImg} alt="" className="nextIconDshBrd" />
        </div>
        <button type="submit" className="saveBtn" onClick={onSave}>
          Submit
        </button>
      </div>
    </footer>
  </div>
</div>)
}
const PersonalIdentityForm =(props)=>{
  //const fistnameRef= useRef(null);
  const client=props.client;
  const setClient=props.setClient;
  props.setFormTitle("Personal Identity");

  useEffect(() => {
    console.log("load personal");
    console.log(client);
 
    }, []);
  return (
    <div className="PersonalIdentityForm ">
      <div className="innerForm ">
        <form action="" className="IDForm">
          <div className="personalIdTitleCultureAndReligion" id="culture">
            <h2 className="cultureAndReligion psdtlHead">
              Culture and Religion
            </h2>
            <div className="IdGrpQues">
              <p className="subTitleHead">Ethnicity</p>
              <label className="IDQues">
                What ethnic group does Adams identify with?
              </label>

              <select name="Ethnicity" className="IDInput"  onChange={(e)=> find(e) }>
                <option value="" disabled selected hidden>
                  Please select an ethnicity
                </option>
                <option value="Not Stated">Not Stated</option>
                <option value="Asian or Asian British">
                  Asian or Asian British: Bangladeshi
                </option>
                <option value="Asian or Asian British: Chinese">
                  Asian or Asian British: Chinese
                </option>
                <option value="Asian or Asian British">
                  Asian or Asian British: Indian
                </option>
                <option value="Asian or Asian British">
                  Asian or Asian British: Paskitani
                </option>
                <option value="Asian or Asian British">
                  Asian or Asian British: Any other asian background
                </option>
                <option value="Black or African or Carribean or Black British">
                  Black or African or Carribean or Black British: African
                </option>
                <option value="Black or African or Carribean or Black British">
                  Black or African or Carribean or Black British: Carribean
                </option>
                <option value="Black or African or Carribean or Black British">
                  Black or African or Carribean or Black British: other Black or
                  African or Carribean background
                </option>
                <option value="Mixed Multiple Ethnic Groups">
                  Mixed Multiple Ethnic Groups: White and Asian
                </option>
                <option value="Mixed Multiple Ethnic Groups">
                  Mixed Multiple Ethnic Groups: White and African
                </option>
                <option value="Mixed Multiple Ethnic Groups">
                  Mixed Multiple Ethnic Groups: White and Carribean
                </option>
                <option value="Mixed Multiple Ethnic Groups">
                  Mixed Multiple Ethnic Groups: any other Mixed or Multiple
                  Ethnic background
                </option>
                <option value="Other Ethnic Group">
                  Other Ethnic Group: Arab
                </option>
                <option value="Other Ethnic Group">
                  Other Ethnic Group: any other ethnic
                </option>
                <option value="White">
                  White: English or Welsh or Scottish or Northern Irish or
                  British
                </option>
                <option value="White">White: Gypsy or Irish Traveller</option>
                <option value="White">White: Irish</option>
                <option value="White">White: any other background</option>
              </select>
            </div>

            <div className="IdGrpQues">
              <p className="subTitleHead">Religion</p>
              <label className="IDQues">
                What religion or belief does Adams identify with? 
              </label>
              <select name="religion" className="IDInput margBt" value={client.religionId} onChange={(e)=>  setClient({...client,religionId:find(e)})}>
                <option value="" disabled selected hidden>
                  Please select a religion
                </option>
                <option value="0">Not stated</option>
                <option value="1">Advaita Vedanta</option>
                <option value="1">
                  Advent Christian Church
                </option>
                <option value="African Religion">African Religion</option>
                <option value="Agnostic Christian">Agnostic Christian</option>
                <option value="Agnostic Movement">Agnostic Movement</option>
                <option value="Ahmadiyya Islam">Ahmadiyya Islam</option>
                <option value="Amish Religion">Amish Religion</option>
                <option value="Anabaptism">Anabaptism</option>
                <option value="Anglican Church">Anglican Church</option>
                <option value="Anthroposophy">Anthroposophy</option>
                <option value="Apostolic Church">Apostolic Church</option>
                <option value="Apostolic Pentecostal Religion">
                  Apostolic Pentecostal Religion
                </option>
                <option value="Arcane School Religion">
                  Arcane School Religion
                </option>
                <option value="Armenian Church">Armenian Church</option>
                <option value="Arminianism">Arminianism</option>
                <option value="Arya Samaj">Arya Samaj</option>
                <option value="Asatru">Asatru</option>
                <option value="Asceticism">Asceticism</option>
                <option value="Assemblies of God">Assemblies of God</option>
                <option value="Assembly of Free Spirit Baptist Church">
                  Assembly of Free Spirit Baptist Church
                </option>
                <option value="Atheist Movement">Atheist Movement</option>
                <option value="Babis Religion">Babis Religion</option>
                <option value="Bahaism">Bahaism</option>
                <option value="Baptist Church">Baptist Church</option>
                <option value="Black Magic">Black Magic</option>
                <option value="Brahma Kumaris">Brahma Kumaris</option>
                <option value="Brahmanism">Brahmanism</option>
                <option value="British Isrealism">British Isrealism</option>
                <option value="Buddhism">Buddhism</option>
                <option value="Bulgarian Orthodox Church">
                  Bulgarian Orthodox Church
                </option>
                <option value="Calvinism">Calvinism</option>
                <option value="Catholic Religion">Catholic Religion</option>
                <option value="Celtic Christian Church">
                  Celtic Christian Church
                </option>
                <option value="Celtic Orthodox Church">
                  Celtic Orthodox Church
                </option>
                <option value="Celtic Paganism">Celtic Paganism</option>
                <option value="Central Asian Buddhist Religion">
                  Central Asian Buddhist Religion
                </option>
                <option value="Chinese Evangelical Christian Church">
                  Chinese Evangelical Christian Church
                </option>
                <option value="Chinese Buddhist Religion">
                  Chinese Buddhist Religion
                </option>
                <option value="Christadelphian Movement">
                  Christadelphian Movement
                </option>
                <option value="Christian Existentialism">
                  Christian Existentialism
                </option>
                <option value="Christian Humanism">Christian Humanism</option>
                <option value="Christian Methodist Episcopal">
                  Christian Methodist Episcopal
                </option>
                <option value="Christian Reformed Church">
                  Christian Reformed Church
                </option>
                <option value="Christian Scientist Religious Movement">
                  Christian Scientist Religious Movement
                </option>
                <option value="Christian Spiritualism">
                  Christian Spiritualism
                </option>
                <option value="Chirstian Religion">Chirstian Religion</option>
                <option value="Church Universal and Triumphant">
                  Church Universal and Triumphant
                </option>
                <option value="Church in Wales">Church in Wales</option>
                <option value="Church of England">Church of England</option>
                <option value="Church of God of Prophecy">
                  Church of God of Prophecy
                </option>
                <option value="Church of Ireland">Church of Ireland</option>
                <option value="Church of Jesus Christ of Latter Day Saints">
                  Church of Jesus Christ of Latter Day Saints
                </option>
                <option value="Church of Religious Science">
                  Church of Religious Science
                </option>
                <option value="Church of Scientology">
                  Church of Scientology
                </option>
                <option value="Church of Scotland">Church of Scotland</option>
                <option value="Church of the Brethren">
                  Church of the Brethren
                </option>
                <option value="Church of the Nazarene">
                  Church of the Nazarene
                </option>
                <option value="Comenianism">Comenianism</option>
                <option value="Confucianism">Confucianism</option>
                <option value="Congregationalist Church">
                  Congregationalist Church
                </option>
                <option value="Conservative Baptist Church">
                  Conservative Baptist Church
                </option>
                <option value="Conservative Jewish Religion">
                  Conservative Jewish Religion
                </option>
                <option value="Coptic Church">Coptic Church</option>
                <option value="Deism">Deism</option>
                <option value="Divine Science Church">
                  Divine Science Church
                </option>
                <option value="Doukhobor Religion">Doukhobor Religion</option>
                <option value="Druze Religion">Druze Religion</option>
                <option value="Eastern Catholic Religion">
                  Eastern Catholic Religion
                </option>
                <option value="Eastern Christianity">
                  Eastern Christianity
                </option>
                <option value="Eastern Orthodox Church">
                  Eastern Orthodox Church
                </option>
                <option value="Eckankar Religion">Eckankar Religion</option>
                <option value="Eminist Religion">Eminist Religion</option>
                <option value="Ethiopian Church">Ethiopian Church</option>
                <option value="Evangelical Church">Evangelical Church</option>
                <option value="Exclusive Brethren">Exclusive Brethren</option>
                <option value="Free Christian Church">
                  Free Christian Church
                </option>
                <option value="Free Church of Scotland">
                  Free Church of Scotland
                </option>
                <option value="Free Evangelical Presbyterian Church">
                  Free Evangelical Presbyterian Church
                </option>
                <option value="Free Presbyterian Church">
                  Free Presbyterian Church
                </option>
                <option value="Free Will Baptist Church">
                  Free Will Baptist Church
                </option>
                <option value="Goddess Tradition">Goddess Tradition</option>
                <option value="Greek Catholic Church">
                  Greek Catholic Church
                </option>
                <option value="Haredi Judaism">Haredi Judaism</option>
                <option value="Hasidim">Hasidim</option>
                <option value="Hinduism">Hinduism</option>
                <option value="Hinduism, Buddhism AND/OR derivative">
                  Hinduism, Buddhism AND/OR derivative
                </option>
                <option value="Humanism">Humanism</option>
                <option value="Humanistic Judaism">Humanistic Judaism</option>
                <option value="Hutterian Brethren">Hutterian Brethren</option>
                <option value="Independent Fundamnetal Christian Church">
                  Independent Fundamnetal Christian Church
                </option>
                <option value="Infinite Way">Infinite Way</option>
                <option value="Islam">Islam</option>
                <option value="Islam AND/OR derivative">
                  Islam AND/OR derivative
                </option>
                <option value="Isma'ilis Religion">Isma'ilis Religion</option>
                <option value="Jainism">Jainism</option>
                <option value="Japanese Buddhist Religion">
                  Japanese Buddhist Religion
                </option>
                <option value="Jehovah's Witness Religion">
                  Jehovah's Witness Religion
                </option>
                <option value="Judaic Christian">Judaic Christian</option>
                <option value="Judaism">Judaism</option>
                <option value="Kabbalah">Kabbalah</option>
                <option value="Krishna Consciousness Religious Movement">
                  Krishna Consciousness Religious Movement
                </option>
                <option value="Lamaism">Lamaism</option>
                <option value="Liberal Jewish Religion">
                  Liberal Jewish Religion
                </option>
                <option value="Liberal Protestant Religion">
                  Liberal Protestant Religion
                </option>
                <option value="Lightworker">Lightworker</option>
                <option value="Lutheran Church">Lutheran Church</option>
                <option value="Mahayana Buddhist Religion">
                  Mahayana Buddhist Religion
                </option>
                <option value="Maronite Church">Maronite Church</option>
                <option value="Mennonite Church">Mennonite Church</option>
                <option value="Messianic Judaism">Messianic Judaism</option>
                <option value="Methodist Church">Methodist Church</option>
                <option value="Mixed Religion">Mixed Religion</option>
                <option value="Moravian Church">Moravian Church</option>
                <option value="Mysticism">Mysticism</option>
                <option value="National Spiritualist Church">
                  National Spiritualist Church
                </option>
                <option value="Native American Religion">
                  Native American Religion
                </option>
                <option value="New Kadampa Tradition - International Kadampa Buddhist Union">
                  New Kadampa Tradition - International Kadampa Buddhist Union
                </option>
                <option value="New Testament Church of God">
                  New Testament Church of God
                </option>
                <option value="New Religious Movement">
                  New Religious Movement
                </option>
                <option value="Nichiren Buddhism">Nichiren Buddhism</option>
                <option value="Nonconformist Religion">
                  Nonconformist Religion
                </option>
                <option value="Occultism">Occultism</option>
                <option value="Old Catholic Church">Old Catholic Church</option>
                <option value="Open Brethren">Open Brethren</option>
                <option value="Open Episcopal Church">
                  Open Episcopal Church
                </option>
                <option value="Orthodox Baptist Church">
                  Orthodox Baptist Church
                </option>
                <option value="Orthodox Christian Religion">
                  Orthodox Christian Religion
                </option>
                <option value="Orthodox Jewish Faith">
                  Orthodox Jewish Faith
                </option>
                <option value="Paganism">Paganism</option>
                <option value="Pantheism">Pantheism</option>
                <option value="Para-religious Movement">
                  Para-religious Movement
                </option>
                <option value="Pentecostal Free Will Baptist Church">
                  Pentecostal Free Will Baptist Church
                </option>
                <option value="Pentecostalist Religion">
                  Pentecostalist Religion
                </option>
                <option value="Peyote Religion">Peyote Religion</option>
                <option value="Pietist Religion">Pietist Religion</option>
                <option value="Plymouth Brethren Religion">
                  Plymouth Brethren Religion
                </option>
                <option value="Presbyterian Church">Presbyterian Church</option>
                <option value="Primal Society Religion">
                  Primal Society Religion
                </option>
                <option value="Primitive Tribal Spiritual Belief">
                  Primitive Tribal Spiritual Belief
                </option>
                <option value="Protestant Religion">Protestant Religion</option>
                <option value="Pure Land Buddhism">Pure Land Buddhism</option>
                <option value="Quaker Religion">Quaker Religion</option>
                <option value="Radhasoami">Radhasoami</option>
                <option value="Rastafarian Movement">
                  Rastafarian Movement
                </option>
                <option value="Reconstructionist Jewish Religion">
                  Reconstructionist Jewish Religion
                </option>
                <option value="Reform Jewsih Faith">Reform Jewsih Faith</option>
                <option value="Reformed Church">Reformed Church</option>
                <option value="Reformed Presbyterian Church">
                  Reformed Presbyterian Church
                </option>
                <option value="Reformed Presbyterian">
                  Reformed Presbyterian
                </option>
                <option value="Reformed Protestant">Reformed Protestant</option>
                <option value="Religion of Japan, China AND/OR Persia">
                  Religion of Japan, China AND/OR Persia
                </option>
                <option value="Rosicrucian Fellowship">
                  Rosicrucian Fellowship
                </option>
                <option value="Rumanian Orthodox Church">
                  Rumanian Orthodox Church
                </option>
                <option value="Russian Orthodox Church">
                  Russian Orthodox Church
                </option>
                <option value="Salvation Army">Salvation Army</option>
                <option value="Santaria">Santaria</option>
                <option value="Satanism">Satanism</option>
                <option value="Scottish Episcopalaian Church">
                  Scottish Episcopalaian Church
                </option>
                <option value="Scottish Protestant Religion">
                  Scottish Protestant Religion
                </option>
                <option value="Serbian Orthodox Church">
                  Serbian Orthodox Church
                </option>
                <option value="Seventh Day Baptist Church">
                  Seventh Day Baptist Church
                </option>
                <option value="Seventh Day Pentecostal Assembly">
                  Seventh Day Pentecostal Assembly
                </option>
                <option value="Seventh Day Adventist Religion">
                  Seventh Day Adventist Religion
                </option>
                <option value="Shakti Hinduism">Shakti Hinduism</option>
                <option value="Shamanism">Shamanism</option>
                <option value="Shi'ite Muslim Religion">
                  Shi'ite Muslim Religion
                </option>
                <option value="Shintoism">Shintoism</option>
                <option value="Shiva Hinduism">Shiva Hinduism</option>
                <option value="Shumei">Shumei</option>
                <option value="Sikhism">Sikhism</option>
                <option value="Sinhalese Buddhist Religion">
                  Sinhalese Buddhist Religion
                </option>
                <option value="South East Asia Buddhist Religion">
                  South East Asia Buddhist Religion
                </option>
                <option value="Spiritualism">Spiritualism</option>
                <option value="Sufi Muslim Religion">
                  Sufi Muslim Religion
                </option>
                <option value="Sunni Muslim Religion">
                  Sunni Muslim Religion
                </option>
                <option value="Swedenborgianism">Swedenborgianism</option>
                <option value="Syrian Orthodox Church">
                  Syrian Orthodox Church
                </option>
                <option value="Taoism">Taoism</option>
                <option value="Theravada Buddhist Religion">
                  Theravada Buddhist Religion
                </option>
                <option value="Tibetan Buddhist Religion">
                  Tibetan Buddhist Religion
                </option>
                <option value="Transcendental Meditation">
                  Transcendental Meditation
                </option>
                <option value="Trinitarian Humanism">
                  Trinitarian Humanism
                </option>
                <option value="Ukranian Catholic Church">
                  Ukranian Catholic Church
                </option>
                <option value="Ultimatism">Ultimatism</option>
                <option value="Unification Church Religious Movement">
                  Unification Church Religious Movement
                </option>
                <option value="Unitarian Universalist Church">
                  Unitarian Universalist Church
                </option>
                <option value="United Church of Canada">
                  United Church of Canada
                </option>
                <option value="United Church of Christ">
                  United Church of Christ
                </option>
                <option value="United Free Church of Scotland">
                  United Free Church of Scotland
                </option>
                <option value="United Methodist Church">
                  United Methodist Church
                </option>
                <option value="United Reform Church">
                  United Reform Church
                </option>
                <option value="Universalism">Universalism</option>
                <option value="Vaishnava Hinduism">Vaishnava Hinduism</option>
                <option value="Vodun">Vodun</option>
                <option value="Wladensian-Methodist Church">
                  Wladensian-Methodist Church
                </option>
                <option value="Western Christianity">
                  Western Christianity
                </option>
                <option value="Western Buddhist Religion">
                  Western Buddhist Religion
                </option>
                <option value="Witchcraft">Witchcraft</option>
                <option value="Yoruba">Yoruba</option>
                <option value="Zen Buddhism">Zen Buddhism</option>
                <option value="Zoroastrianism">Zoroastrianism</option>
                <option value="Zwinglianism">Zwinglianism</option>
              </select>

              <label className="IDQues">
                What religion or belief does Adams identify with?
              </label>
              <textarea
                name=""
                className="IDInput" value={client.religionInfo} onChange={(e)=> setClient({...client,religionInfo:e.target.value})}
                placeholder="e.g. Details of religion belief"
              ></textarea>
            </div>
          </div>

          <div className="personalIdTitleSexuality" id="sexuality">
            <h2 className="Sexuality psdtlHead">Sexuality </h2>
            <label className="IDQues">
              If you do not collect this information already, this section can
              be skipped.
            </label>
            <div className="IdGrpQues">
              <p className="subTitleHead">Sex</p>
              <label className="IDQues">
                What sex has Adams been assigned at birth?
              </label>

              <div className="options">
              <RadioOption type="radio" title="Female" checked={client.sex=='Female'?true:false} onClick={()=> setClient({...client,sex:'Female'})} />
              <RadioOption type="radio" title="Male" checked={client.sex=='Male'?true:false} onClick={()=> setClient({...client,sex:'Male'})} />
              <RadioOption type="radio" title="Intersex" checked={client.sex=='Intersex'?true:false} onClick={()=> setClient({...client,sex:'Intersex'})} />  
              <RadioOption type="radio" title="Rather not say" checked={client.sex=='Rather not say'?true:false} onClick={()=> setClient({...client,sex:'Rather not say'})} />
                
              </div>
            </div>

            <div className="IdGrpQues">
              <p className="subTitleHead">Gender</p>
              <label className="IDQues">
                Which of these best describes Adams's current gender?
              </label>

              <div className="options">
              <RadioOption type="radio" title="Female" checked={client.gender=='Female'?true:false} onClick={()=> setClient({...client,gender:'Female'})} />
              <RadioOption type="radio" title="Male" checked={client.gender=='Male'?true:false} onClick={()=> setClient({...client,gender:'Male'})} />
              <RadioOption type="radio" title="Intersex" checked={client.gender=='Intersex'?true:false} onClick={()=> setClient({...client,gender:'Intersex'})} />  
              <RadioOption type="radio" title="Rather not say" checked={client.gender=='Rather not say'?true:false} onClick={()=> setClient({...client,gender:'Rather not say'})} />
                
              </div>
            </div>

            <div className="IdGrpQues">
              <p className="subTitleHead">Sexual Orientation</p>
              <label className="IDQues">
                What best describes Adams's current sexual orientation?
              </label>

              <input
                type="text" value={client.sexOrientation} onChange={(e)=> setClient({...client,sexOrientation:e.target.value})}
                placeholder="e.g heterosexual"
                className="IDInput"
              />
            </div>

            <div className="IdGrpQues">
              <label className="IDQues">
                How does sex, gender or sexual orientation impact Adams's care
                needs?
              </label>

              <textarea 
                type="text" value={client.sexInfo} onChange={(e)=> setClient({...client,sexInfo:e.target.value})}
                placeholder="e.g. Clients prefers to be called they"
                className="IDInput"
              ></textarea>
            </div>
          </div>

          <div className="personalIdTitleLifeHistory" id="lifehistory">
            <h2 className="LifeHistory psdtlHead">Life History</h2>

            <div className="IdGrpQues">
              <p className="subTitleHead">Jobs and Occupations</p>
              <label className="IDQues">
                Does Adams have any current jobs or past jobs that are important
                to them? How do they impact their care needs?
              </label>

              <textarea
                name=""
                className="IDInput" value={client.job} onChange={(e)=> setClient({...client,job:e.target.value})}
                placeholder="Describe any of the client's current or past jobs, any volunteering activities."
              ></textarea>
            </div>

            <div className="IdGrpQues">
              <p className="subTitleHead">Important People</p>
              <label className="IDQues">
                Does Adams have any people who are important to them? How do
                they impact their care needs?
              </label>

              <textarea
                name=""
                className="IDInput" value={client.peoples} onChange={(e)=> setClient({...client,peoples:e.target.value})}
                placeholder="Describe anyone important to the client, their relationship and why they are important."
              ></textarea>
            </div>

            <div className="IdGrpQues">
              <p className="subTitleHead">Significant places</p>
              <label className="IDQues">
                Does Adams have any places that are important to them? How do
                they impact their care needs?
              </label>

              <textarea
                name=""
                className="IDInput" value={client.places} onChange={(e)=> setClient({...client,places:e.target.value})}
                placeholder="Describe any of the client's former or childhood homes, any favorite or significant places visited."
              ></textarea>
            </div>

            <div className="IdGrpQues">
              <p className="subTitleHead">Other Notes</p>
              <label className="IDQues">
                Are there any other notes about Adams's life history that are
                important? How do they impact Adams's care needs?
              </label>

              <textarea value={client.otherInfo} onChange={(e)=> setClient({...client,otherInfo:e.target.value})}
                name=""
                className="IDInput"
                placeholder="Describe anything else that hasn't been covered by previous questions."
              ></textarea>
            </div>
          </div>

          <div className="personalIdTitlePreferences" id="preferences">
            <h2 className="LifeHistory psdtlHead">Preferences</h2>

            <div className="IdGrpQues">
              <p className="subTitleHead">Routines and Preferencess</p>
              <label className="IDQues">
                DDoes Adams have any specific routines or preferences that are
                important to them? How do they impact their care needs?
              </label>

              <textarea value={client.routines} onChange={(e)=> setClient({...client,routines:e.target.value})}
                name=""
                className="IDInput"
                placeholder="Describe any of the client's current routines, such as getting up at a certain time, having meals at a certain time, doing activities in a certain order."
              ></textarea>
            </div>

            <div className="IdGrpQues">
              <p className="subTitleHead">Dislikes</p>
              <label className="IDQues">
                Does Adams have anything that may worry or upset them? How do
                they impact their care needs?
              </label>

              <textarea value={client.dislikes} onChange={(e)=> setClient({...client,dislikes:e.target.value})}
                name=""
                className="IDInput"
                placeholder="Describe anyone important to the client, their relationship and why they are important."
              ></textarea>
            </div>

            <div className="IdGrpQues">
              <p className="subTitleHead">Hobbies and Interests</p>
              <label className="IDQues">
                Does Adams have any hobbies or interests? How do they impact
                their care needs?
              </label>

              <textarea
                name="" value={client.hobbies} onChange={(e)=> setClient({...client,hobbies:e.target.value})}
                className="IDInput"
                placeholder="Describe any of the client's passions, hobbies, sports, clubs and memberships."
              ></textarea>
            </div>

            <div className="IdGrpQues magrBtm">
              <p className="subTitleHead">Other Notes</p>
              <label className="IDQues">
                Are there any other notes about Adams's life history that are
                important? How do they impact Adams's care needs?
              </label>

              <textarea
                name=""
                className="IDInput"
                placeholder="Describe anything else that hasn't been covered by previous questions."
              ></textarea>
            </div>
          </div>

          <EditForm title="Personal Identity" />
        </form>

        <div className="formSidePanel">
          <p className="formSidePanelTitle">PERSONAL IDENTITY</p>
          <ul className="formSidePanelLists">
            <li className="formSidePanelLink">
              <HashLink to="#culture" smooth className="fomLink">
                Culture and Religion
              </HashLink>
            </li>
            <li className="formSidePanelLink">
              <HashLink to="#sexuality" smooth className="fomLink">
                Sexuality
              </HashLink>
            </li>
            <li className="formSidePanelLink">
              <HashLink to="#lifehistory" smooth className="fomLink">
                Life History
              </HashLink>
            </li>
            <li className="formSidePanelLink">
              <HashLink to="#preferences" smooth className="fomLink">
                Preferences
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const ClinicalDetailsForm =(props) =>{
  const client=props.client;
  const {setClient}=props;
  props.setFormTitle("Clinical Details");
  return (
    <div className="ClinicalDetailsForm">
      <div className="innerForm">
        <form action="" className="IDForm">
          <div className="clinicalFrmInGrps">
           
            <h2 className=" psdtlHead" id="healthdetails">
            Health Details
            </h2>
          

            <div className="IdGrpQues">
              <label className="IDQues">NHS Number</label>
              <input type="number" className="IDInput" value={client.nHS}   onChange={(e)=>setClient({...client,nHS:e.target.value})} key="nHS" name="nHS" />
            </div>
            <div className="IdGrpQues">
              <label className="IDQues">Medical History </label>
              <input type="text" className="IDInput" value={client.medicalHistory} onChange={(e)=>setClient({...client,medicalHistory:e.target.value})} />

              <div class="clinicCheck">
                <input type="checkbox" name="" className="checkbox" />
                <label className="checkboxText">
                  Restrict search to clinical findings and procedures
                </label>
              </div> 
            </div>
           

          

            <div className="IdGrpQues">
              <label className="subTitleHead">Medical Support</label>
              <div className="options">
              <RadioOption type="radio" title="We provide Adams's medicine support" checked={client.medicalSupport=='support'?true:false} onClick={()=> setClient({...client,title:'support'})} />
              <RadioOption type="radio" title="We provide Adams's medicine support" checked={client.medicalSupport=='notsupport'?true:false} onClick={()=> setClient({...client,title:'notsupport'})} />
               
              
                
              </div>
              
            </div>
          </div>

          <div className="IdGrpQues">
           
            <label className="subTitleHead">Allergies and Intolerances</label>
            <label className="IDQues">
                Allergies and Intolerances
              </label>
              <textarea value={client.allergies} onChange={(e)=> setClient({...client,allergies:e.target.value})}
                className="IDInput"
                placeholder="You are required to add any information about any allergies or intolerances here. If there are none, please write 'None Known.'"
              ></textarea>

          </div>

          <div className="IdGrpQues">
          <label className="subTitleHead">Doctor/GP</label>
          <div>
            <label className="IDQues">GP's Name</label>
              <input value={client.docName} onChange={(e)=> setClient({...client,docName:e.target.value})}
                type="text"
                className="IDInput"
                placeholder="e.g. Dr Jane Smith"
              />
          </div>
             <div>
              <label className="IDQues">Contact Number</label>
              <input
                type="tel" value={client.docPhone} onChange={(e)=> setClient({...client,docPhone:e.target.value})}
                className="IDInput"
                placeholder="e.g. 01555 700100"
              />
             </div>
            
           
          </div>

          <div className="IdGrpQues magrBtm">
           
            <label className="subTitleHead">Pharmacist</label>

            <div>
              <label className="IDQues">Pharmacy's Name</label>
              <input
                type="text"
                className="IDInput"
                placeholder="e.g. Lloyd's Pharmacy"
              />
            </div>
            <div>
              <label className="IDQues">Pharmacy Address</label>
              <input
                type="text" value={client.pharmacyName} onChange={(e)=> setClient({...client,pharmacyName:e.target.value})}
                className="IDInput"
                placeholder="e.g. 100 High Street, London"
              />
            </div>
            <div>
              <label className="IDQues">Pharmacy Address</label>
              <input value={client.pharmacyAddress} onChange={(e)=> setClient({...client,pharmacyAddress:e.target.value})}
                type="text"
                className="IDInput"
                placeholder="e.g. N1 7RT"
              />
            </div>
            <div>
              <label className="IDQues">Post Code</label>
              <input value={client.pharmacyPostCode} onChange={(e)=> setClient({...client,pharmacyPostCode:e.target.value})}
                type="text"
                className="IDInput"
                placeholder="e.g. "
              />
            </div>
          </div>

          <EditForm title="Clinical Details" />
        </form>

        <div className="formSidePanel">
          <p className="formSidePanelTitle">Clinical Details</p>
          <ul className="formSidePanelLists">
            <li className="formSidePanelLink">
              <HashLink smooth to="#healthdetails" className="fomLink">
                Health Details
              </HashLink>
            </li>
            <li className="formSidePanelLink">
              <HashLink
                smooth
                to="#allergiesandintolerances"
                className="fomLink"
              >
                Allergies and intolerances
              </HashLink>
            </li>
            <li className="formSidePanelLink">
              <HashLink smooth to="#doctor" className="fomLink">
                Doctor/GP
              </HashLink>
            </li>
            <li className="formSidePanelLink">
              <HashLink smooth to="#pharmacist" className="fomLink">
                Pharmacist
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
const KeyContactForm =(props) =>{
  const client=props.client;
  const setClient=props.setClient;
  props.setFormTitle("Key Contact");
  useEffect(() => {
    console.log("load contact");
    console.log(client);
 
    }, []);
  const onAddContact=()=>{
    var copyc={...client};
    
   let newcontacts=[...copyc.contacts,{id:0}];
   var newclient={...client,contacts:newcontacts}
   setClient(newclient);
  
   
   }
   const onRemoveContact =(index) =>{
     console.log('selected index for removal '+index);
     var copyc={...client};
     var contact_delete=client.contacts[index];
   
     console.log('single Item');
     console.log(contact_delete);
     if(contact_delete.id==0){
       let fi =client.contacts.splice(index, 1);
       console.log(fi)
     copyc.contacts=fi;
      setClient(copyc);
     }else{
       //call db to remove and re-adjust
     }
     
   }
    const onAddProfessional=()=>{
    
  

      var copyc={...client};
    
      let newps=[...copyc.professionals,{id:0}];
      //copyc.professionals=newps;
      var newclient={...client,professionals:newps}
   setClient(newclient);
      setClient(copyc);
   }

   const onRemoveProfessional =(index) =>{
     console.log('selected index for removal '+index);
     var copyc={...client};
     var prof_delete=client.professionals[index];
   
     console.log('single Item');
     console.log(prof_delete);
     if(prof_delete.id==0){
       //means its new, you can remove it
       //let fi = coreItem.contacts.filter(c => c.sn!=sn ) ;  //a serial number will be included to remove
       let fi =client.professionals.splice(index, 1);
       console.log(fi)
     //contacts=fi; //syncContactlist(fi) ;
     copyc.professionals=fi;
      setClient(copyc);
     }else{
       //call db to remove and re-adjust
     }
     
   }
  return (
    <div className="KeyContactForm">
      <div className="innerForm">
        <div action="" className="IDForm">
        {client.contacts.map((contact, idex) => (<div className="keyCtFrmInGrps" key={idex}>
            <p className="psdtlHead" id="nok">
              Nok/emergency Contacts ({idex+1})
            </p>

            <div className="IdGrpQues">
              <label className="IDQues">Full Name</label>
              <input value={contact.fullName}
                type="text" key="fullName"
                className="IDInput"
                placeholder="Enter Full Name" onChange={e => {

                  var copyc=[...client.contacts];
                  copyc[idex].fullName = e.target.value;
                  var newclient={...client,contacts:copyc}
                  setClient(newclient);

                }}
              />
            </div>

            <div className="IdGrpQues">
              <label className="IDQues">Relationship with contact</label>
              <select name="Relationship with contact" className="IDInput" value={contact.relationship} onChange={e => {
                
                var copyc=[...client.contacts];
                copyc[idex].relationship =find(e) ;
                var newclient={...client,contacts:copyc}
                setClient(newclient);

              }}>
                <option value=""  selected>
                  Please select the type of Relationship
                </option>
                <option value="Aunt">Aunt</option>
                <option value="Child">Child</option>
                <option value="Cousin">Cousin</option>
                <option value="Daughter">Daughter</option>
                <option value="Friend">Friend</option>
                <option value="Grandchild">Grandchild</option>
                <option value="Nephew">Nephew</option>
                <option value="Niece">Niece</option>
                <option value="Parent">Parent</option>
                <option value="Spouse">Spouse</option>
                <option value="Sibling">Sibling</option>
                <option value="Son">Son</option>
                <option value="Uncle">Uncle</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="IdGrpQues">
              <label className="subTitleHead">Phone Number</label>
              <div className="hldTogether">
                <Phone  value={contact.phone} onChange={e => {
                
                var copyc=[...client.contacts];
                copyc[idex].phone = e;
                var newclient={...client,contacts:copyc}
                setClient(newclient);

              }}/>
              

               
              </div>
            </div>

            <div className="IdGrpQues">
              <label className="IDQues">E-mail</label>
              <input
                type="email" onChange={e => {
                
                  var copyc=[...client.contacts];
                  copyc[idex].email = e.target.value;
                  var newclient={...client,contacts:copyc}
                  setClient(newclient);

                }}
                className="IDInput"
                placeholder="Enter email"
              />
            </div>

            <div className="options">
              <label className="subTitleHead">Type of Contact</label>
              <RadioOption type="check" title="Emergency" checked={contact.isEmergency} onClick={()=> {
                
                var copyc=[...client.contacts];
                copyc[idex].isEmergency = !copyc[idex].isEmergency;
                var newclient={...client,contacts:copyc}
                setClient(newclient);
                }} />
              <RadioOption type="check" title="Next of kin" checked={contact.isNextOFKin} onClick={()=> 
                {
                  var copyc=[...client.contacts];
                copyc[idex].isNextOFKin = !copyc[idex].isNextOFKin;
                var newclient={...client,contacts:copyc}
                setClient(newclient);
                }
                } /> 
            </div>

            <div className="options">
              <label className="subTitleHead">
                Does the client or person making a best interests decision on
                their behalf agree that general care matters can be discussed
                with this contact?
              </label>
              <ButtonSwitch yesTitle="Yes" noTitle="No" value={contact.isAgreed?1:2} 
              onSelected={(e)=>{
                let s=e==1?true:false;
                var copyc=[...client.contacts];
                copyc[idex].isAgreed = s;
                var newclient={...client,contacts:copyc}
                setClient(newclient);
                console.log('selected option is '+s);
            }
              } />
             
              
            </div>
            <button class="addNok cancelBtn" onClick={()=>onRemoveContact(idex)}>Remove Contact {idex+1}</button>
            
          </div>))}
          

          <div>
            <button class="addNok saveBtn" type="button" onClick={onAddContact}>Add another NoK/emergency contact {client.contacts.length+1}</button>
          </div>
          {client.professionals.map((prof,idex) => (<div className="magTop" key={idex}>
            <p className="inbetweenHead psdtlHead" id="otherprofessional">
              Other Professionals ({idex+1})
            </p>

            <div className="IdGrpQues">
              <label className="IDQues">Full Name</label>
              <input value={prof.fullName}
                type="text" onChange={e => {

                  var copyp=[...client.professionals];
                  copyp[idex].fullName = e.target.value;
                  var newclient={...client,professionals:copyp}
                  setClient(newclient);

                }}
                className="IDInput"
                placeholder="Enter Full Name"
              />
            </div>

            <div className="IdGrpQues">
              <label className="IDQues">Role</label>
              <select name="Relationship with contact" value={prof.role} className="IDInput" onChange={e => {

              var copyp=[...client.professionals];
              copyp[idex].role =find(e) ;
              var newclient={...client,professionals:copyp}
              setClient(newclient);

              }}>
                <option value="" disabled hidden selected>
                  Please select the role of this contact
                </option>
                <option value="Advocate">Advocate</option>
                <option value="Care co-ordinator">Care co-ordinator</option>
                <option value="CHC Nurse">CHC Nurse</option>
                <option value="Dietician">Dietician</option>
                <option value="District Nurse">District Nurse</option>
                <option value="Mental Health Nurse">Mental Health Nurse</option>
                <option value="Occupational Therapist">
                  Occupational Therapist
                </option>
                <option value="Physiotherapist">Physiotherapist</option>
                <option value="Speech and Language Therapist">
                  Speech and Language Therapist
                </option>
                <option value="Social Worker">Social Worker</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="IdGrpQues">
              <label className="IDQues">Service Name</label>
              <input value={prof.serviceName}
                type="text" onChange={e => {

                  var copyp=[...client.professionals];
                  copyp[idex].serviceName = e.target.value;
                  var newclient={...client,professionals:copyp}
                  setClient(newclient);

                }}
                className="IDInput"
                placeholder="Enter Full Name"
              />
            </div>
            <div className="IdGrpQues">
              <label className="IDQues">Phone Number</label>
              <Phone  value={prof.phone} onChange={e => {
                
                var copyp=[...client.professionals];
                copyp[idex].phone = e;
                var newclient={...client,professionals:copyp}
                setClient(newclient);

              }}/>
            </div>

            <div className="IdGrpQues">
              <label className="IDQues">E-mail</label>
              <input
              onChange={e => {

                var copyp=[...client.professionals];
                copyp[idex].email = e.target.value;
                var newclient={...client,professionals:copyp}
                setClient(newclient);

              }} value={prof.email}
                type="text"
                className="IDInput"
                placeholder="Enter email"
              />
            </div>

            <div className="options">
              <label className="IDQues">
                Does the client or person making a best interests decision on
                their behalf agree that general care matters can be discussed
                with this contact?
              </label>
              <ButtonSwitch yesTitle="Yes" noTitle="No" value={prof.isAgreed?1:2} 
              onSelected={(e)=>{
                let s=e==1?true:false;
                var copyp=[...client.professionals];
                copyp[idex].isAgreed = s;
                var newclient={...client,professionals:copyp}
                setClient(newclient);
                console.log('selected option is '+s);
            }
              } />

            </div>

            <button class="addNok cancelBtn" onClick={()=>onRemoveProfessional(idex)}>Remove Professional {idex+1}</button>
            
          </div>))}
          

          <div className="magrBtm"> 
            <button class="addNok saveBtn" onClick={onAddProfessional}>Add another Professionals contact {client.professionals.length+1}</button>
          </div>
          <EditForm title="Key Contacts" />
        </div>

        <div className="formSidePanel">
          <p className="formSidePanelTitle">KEY CONTACTS</p>
          <ul className="formSidePanelLists">
            <li className="formSidePanelLink">
              <HashLink smooth to="#nok" className="fomLink">
                NoK/emergency Contacts
              </HashLink>
            </li>
            <li className="formSidePanelLink">
              <HashLink smooth to="#otherprofessional" className="fomLink">
                Other Professionalss
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
const FuturePlanningForm =(props)=>{
  const client=props.client;
  const setClient=props.setClient;
  props.setFormTitle("Future Planning");
  return (
    <div className="FuturePlanningForm">
      <div className="innerForm">
        <form action="" className="IDForm">
          <div className="FuturePlanningInGrps1" id="capacityanddocumentation">
            <p className="psdtlHead">
              Capacity and Documentattion
            </p>

            <div className="IdGrpQues">
              <label className="subTitleHead">
                Does Adams have capacity to make decisions related to their
                health and wellbeing?
              </label>
              <div className="options">
                <RadioOption type="radio" title="Yes" checked={client.choiceCapacity=='Y'?true:false} onClick={()=> setClient({...client,choiceCapacity:'Y'})} />
                <RadioOption type="radio" title="No" checked={client.choiceCapacity=='N'?true:false} onClick={()=> setClient({...client,choiceCapacity:'N'})} />
                <RadioOption type="radio" title="I don't know" checked={client.choiceCapacity=='I'?true:false} onClick={()=> setClient({...client,choiceCapacity:'I'})} />
              </div>
              
            </div>
          </div>

          <div className="IdGrpQues">
            <p className="subTitleHead">Health and Welfare LPA</p>

            <div className="">
              <label className="IDQues">
                Has Adams made a LPA for health and welfare?
              </label>
              <div className="options">
                <RadioOption type="radio" title="Yes" checked={client.healthLPA=='Y'?true:false} onClick={()=> setClient({...client,healthLPA:'Y'})} />
                <RadioOption type="radio" title="No" checked={client.healthLPA=='N'?true:false} onClick={()=> setClient({...client,healthLPA:'N'})} />
                <RadioOption type="radio" title="I don't know" checked={client.healthLPA=='I'?true:false} onClick={()=> setClient({...client,healthLPA:'I'})} />
              </div>
             
            </div>
          </div>
          {client.healthLPA=='Y'?<div className="labelAndInputs">
              <label htmlFor="" className="dashbLable">
             Health/Welfare LPA reference number
              </label>
              <input
                 value={client.healthLPARef} onChange={(e)=> setClient({...client,healthLPARef:e.target.value})}  
                required
                type="text"
                className="firstNameInpt"
                placeholder="LPA Reference"
              />
            </div>:null}

          <div className="IdGrpQues">
            <p className="subTitleHead">
              Property and Financial Affairs LPA
            </p>

            <div className="">
              <label className="IDQues">
                Has Adams made a LPA for property and financial affairs?
              </label>
              <div className="options">
              <RadioOption type="radio" title="Yes" checked={client.financialLPA=='Y'?true:false} onClick={()=> setClient({...client,financialLPA:'Y'})} />
                <RadioOption type="radio" title="No" checked={client.financialLPA=='N'?true:false} onClick={()=> setClient({...client,financialLPA:'N'})} />
                <RadioOption type="radio" title="I don't know" checked={client.financialLPA=='I'?true:false} onClick={()=> setClient({...client,financialLPA:'I'})} />
              </div>
             
            </div>
          </div>
            {client.financialLPA=='Y'?
            <div className="labelAndInputs">
                    <label htmlFor="" className="dashbLable">
                   Financial LPA reference number
                    </label>
                    <input
                      value={client.finLPARef} onChange={(e)=> setClient({...client,finLPARef:e.target.value})}  
                      required
                      type="text"
                      className="firstNameInpt"
                      placeholder="LPA Reference"
                    />
                  </div>:null}

          <div className="IdGrpQues">
            <p className="subTitleHead">
              Do Not Attempt Cardiopulmonary Resuscitation (DNACPR)
            </p>

            <div>
              <label className="IDQues">
                Does Adams have a DNACPR order in place?
              </label>
              <div className="options">
                <RadioOption type="radio" title="Yes" checked={client.dnacpr=='Y'?true:false} onClick={()=> setClient({...client,dnacpr:'Y'})} />
                <RadioOption type="radio" title="No" checked={client.dnacpr=='N'?true:false} onClick={()=> setClient({...client,dnacpr:'N'})} />
                <RadioOption type="radio" title="I don't know" checked={client.dnacpr=='I'?true:false} onClick={()=> setClient({...client,dnacpr:'I'})} />
              </div>
             {/*  <div className="grpButtons">
                <button className="yes padBtn">Yes</button>
                <button className="no padBtn">No</button>
                <button className="noKnow padBtn">Don't Know</button>
              </div> */}
            </div>
          </div>
          {client.dNACPR=='Y'?
            <div className="labelAndInputs">
                    <label htmlFor="" className="dashbLable">
                    Where is it kept?
                    </label>
                    <textarea
                      value={client.whereDNACPR} onChange={(e)=> setClient({...client,whereDNACPR:e.target.value})}  
                      required
                      type="text"
                      className="IDInput"
                      placeholder="e.g studio desk drawer"
                    ></textarea>
                  </div>:null}

          <div className="IdGrpQues">
            <p className="subTitleHead">
              Advance Decision to Refuse Treatment (ADRT / Living Will)
            </p>

            <div className="">
              <label className="IDQues">
                Does Adams have an ADRT in place?
              </label>
              <div className="options">
                <RadioOption type="radio" title="Yes" checked={client.refuseTreatment=='Y'?true:false} onClick={()=> setClient({...client,refuseTreatment:'Y'})} />
                <RadioOption type="radio" title="No" checked={client.refuseTreatment=='N'?true:false} onClick={()=> setClient({...client,refuseTreatment:'N'})} />
                <RadioOption type="radio" title="I don't know" checked={client.refuseTreatment=='I'?true:false} onClick={()=> setClient({...client,refuseTreatment:'I'})} />
              </div>
             
            </div>
          </div>
          {client.refuseTreatment=='Y'?
            <div className="labelAndInputs">
                    <label htmlFor="" className="dashbLable">
                    Where is it kept?
                    </label>
                    <textarea
                      value={client.whereADRT} onChange={(e)=> setClient({...client,whereADRT:e.target.value})}  
                      required
                      type="text"
                      className="IDInput"
                      placeholder="e.g studio desk drawer"
                    ></textarea>
                  </div>:null}
          <div className="IdGrpQues">
            <p className="subTitleHead">
              Recommended Summary Plan for Emergency Care and Treatment
              (ReSPECT)
            </p>

            <div className="magrBtm">
              <label className="IDQues">
                Does Adams have a ReSPECT in place?
              </label>
              <div className="options">
                <RadioOption type="radio" title="Yes" checked={client.reSPECT=='Y'?true:false} onClick={()=> setClient({...client,reSPECT:'Y'})} />
                <RadioOption type="radio" title="No" checked={client.reSPECT=='N'?true:false} onClick={()=> setClient({...client,reSPECT:'N'})} />
                <RadioOption type="radio" title="I don't know" checked={client.reSPECT=='I'?true:false} onClick={()=> setClient({...client,reSPECT:'I'})} />
              </div>
            </div>
          </div>
          {client.reSPECT=='Y'?
            <div className="labelAndInputs magrBtm">
                    <label htmlFor="" className="dashbLable">
                    Where is it kept?
                    </label>
                    <textarea
                      value={client.whereReSPECT} onChange={(e)=> setClient({...client,whereReSPECT:e.target.value})}  
                      required
                      type="text"
                      className="IDInput"
                      placeholder="e.g studio desk drawer"
                    ></textarea>
                  </div>:null}
        </form>

        <div className="formSidePanel">
          <p className="formSidePanelTitle">FUTURE PLANNING</p>
          <ul className="formSidePanelLists">
            <li className="formSidePanelLink">
              <HashLink
                smooth
                to="#capacityanddocumentation"
                className="fomLink"
              >
                Capacity and documentation
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
const AgencyAdminForm =(props) =>{
  const client=props.client;
  const setClient=props.setClient;
  props.setFormTitle("Agency Admin");
  return (
    <div className="AgencyAdminForm">
      <div className="innerForm">
        <form action="" className="IDForm">
          <div className="" id="status">
            <h2 className=" psdtlHead">Status</h2>
            <div className="AgencyAdminSubHead">
              <label className="subTitleHead">Service start date</label>

              <input type="date" className="IDInput" value={client.serviceStart} onChange={(e)=> setClient({...client,serviceStart:e.target.value})}   />
            </div>
          </div>

          <div className="AgencyAdminGrp" id="regulatedcare">
            <h2 className="inbetweenHead psdtlHead">Regulated Care</h2>
            <div className="IDQues">
              <label className="subTitleHead">
                Does Adams receive regulated care?
              </label>

              <div className="options">
                <RadioOption type="radio" title="Yes" checked={client.isRegulatedCare} onClick={()=> setClient({...client,isRegulatedCare:true})} />
                <RadioOption type="radio" title="No" checked={client.IsRegulatedCare} onClick={()=> setClient({...client,isRegulatedCare:false})} />
                
              </div>
              
            </div>
          </div>

          <div className="AgencyAdminGrp" id="riskmanagement">
            <h2 className="inbetweenHead psdtlHead">Risk management</h2>
            <div className="IDQues">
              <label className="subTitleHead">
                Assign an overall risk level to Adams in line with your
                contingency plan.
              </label>
              <div className="options">
                <RadioOption type="radio" title="Red" checked={client.title=='Red'?true:false} onClick={()=> setClient({...client,risk:'Red'})} />
                <RadioOption type="radio" title="Amber" checked={client.title=='Amber'?true:false} onClick={()=> setClient({...client,risk:'Amber'})} />
                <RadioOption type="radio" title="Green" checked={client.title=='Green'?true:false} onClick={()=> setClient({...client,risk:'Green'})} />
              </div>
              

              <label className="subTitleHead">Risk level details</label>
              <textarea value={client.riskDetails} onChange={(e)=> setClient({...client,riskDetails:e.target.value})}  
                name=""
                placeholder="Explain the reason for the risk level assigned to this client"
                className="IDInput"
              ></textarea>
              <div>
                <label className="subTitleHead">Family involvement level</label>
                <div className="options">
                <RadioOption type="radio" title="Very Involved" checked={client.familyInvolvement=='Very Involved'?true:false} onClick={()=> setClient({...client,familyInvolvement:'Very Involved'})} />
                <RadioOption type="radio" title="Involved" checked={client.familyInvolvement=='Involved'?true:false} onClick={()=> setClient({...client,familyInvolvement:'Involved'})} />
                <RadioOption type="radio" title="Somewhat Involved" checked={client.familyInvolvement=='Somewhat Involved'?true:false} onClick={()=> setClient({...client,familyInvolvement:'Somewhat Involved'})} />
                <RadioOption type="radio" title="Not Involved" checked={client.familyInvolvement=='Not Involved'?true:false} onClick={()=> setClient({...client,familyInvolvement:'Not Involved'})} />
              </div>
              </div>
             

              <label className="subTitleHead">
                What is the contingency plan for Adams 's care, in the case of a
                staffing crisis?
              </label>
              <textarea
                name=""
                placeholder="Describe how care should be managed if a staffing crisis were to occur"
                className="IDInput"
              ></textarea>

              <label className="subTitleHead">
                What is the contingency plan for Adams 's care, in the case of
                adverse weather conditions?
              </label>
              <textarea
                name=""
                placeholder="Describe how care should be managed if adverse weather conditions were to occur"
                className="IDInput"
              ></textarea>
            </div>
          </div>

          <div  id="accessibleinfostandard">
            <h2 className="inbetweenHead psdtlHead">
              Accessible Information Standard
            </h2>
            <div className="AgencyAdminSubHead">
              <label className="subTitleHead">
                Does Adams have any communication or information needs?
              </label>

              <div className="options">
                <label className="subTitleHead">Family involvement level</label>
                <div className="option">
                  <input type="checkbox" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Requires specific contact method
                  </label>
                </div>
                <div className="option">
                  <input type="checkbox" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Requires specific information format
                  </label>
                </div>
                <div className="option">
                  <input type="checkbox" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Requires communication professionald
                  </label>
                </div>
                <div className="option">
                  <input type="checkbox" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Requires communication support
                  </label>
                </div>
              </div>

              <label className="subTitleHead">Additional Details</label>
              <textarea name="" className="IDInput"></textarea>

              <div className="options marBtmm">
                <label className="subTitleHead">
                  What is Adams's preferred method of contact for admin matters?
                </label>
                <div className="option">
                  <input type="checkbox" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Mail
                  </label>
                </div>
                <div className="option">
                  <input type="checkbox" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Email
                  </label>
                </div>
                <div className="option">
                  <input type="checkbox" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Phone
                  </label>
                </div>
                <div className="option">
                  <input type="checkbox" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Text
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div id="fundingarrangement">
            <h2 className="inbetweenHead psdtlHead">Funding</h2>
            <div className="AgencyAdminSubHead">
              <label className="subTitleHead">
                Please select one or more funding options:
              </label>

              <div className="options marBtmm">
                <div className="option">
                  <input type="radio" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Local Authority
                  </label>
                </div>
                <div className="option">
                  <input type="radio" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Private
                  </label>
                </div>
                <div className="option">
                  <input type="radio" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    NHS
                  </label>
                </div>
                <div className="option">
                  <input type="radio" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Other
                  </label>
                </div>
              </div>

              <label className="subTitleHead">Local Authority ID</label>
              <input
                type="date"
                className="IDInput"
                placeholder="e.g. 63 001 20190626"
              />
            </div>
          </div>

          <div className="AgencyAdminGrp" id="matching">
            <h2 className="inbetweenHead psdtlHead">Matching</h2>
            <label className="subTitleHead">
              If you do not collect this information already, this section can
              be skipped.
            </label>
            <div className="AgencyAdminSubHead">
              <label className="subTitleHead">Carer Preferences</label>

              <div className="options marBtmm">
                <div className="option">
                  <input type="radio" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Female
                  </label>
                </div>
                <div className="option">
                  <input type="radio" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Male
                  </label>
                </div>
                <div className="option">
                  <input type="radio" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Non-binary
                  </label>
                </div>
                <div className="option">
                  <input type="radio" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    No preference
                  </label>
                </div>
              </div>

              <label className="subTitleHead">Other Preferences</label>
              <label className="subTitleHead">
                Does Adams have any other preferences? How do they impact their
                care needs?
              </label>

              <textarea
                name=""
                placeholder="e.g. would prefer not to be matched with a carer who smokes; would prefer to be matched with a carer who has a cat."
                className="IDInput"
              ></textarea>

              <div className="options magrBtm">
                <label className="subTitleHead">Group</label>

                <div className="option">
                  <input type="radio" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Trafford
                  </label>
                </div>
                <div className="option">
                  <input type="radio" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Derby
                  </label>
                </div>
                <div className="option">
                  <input type="radio" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Broughton
                  </label>
                </div>
                <div className="option">
                  <input type="radio" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Tameside
                  </label>
                </div>
                <div className="option">
                  <input type="radio" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Merseyside
                  </label>
                </div>
                <div className="option">
                  <input type="radio" className="supportRadio" />
                  <label for="Provide" className="optLabl">
                    Talford
                  </label>
                </div>
              </div>
            </div>
          </div>

          <EditForm title="Admin" />
        </form>

        <div className="formSidePanel rightP">
          <p className="formSidePanelTitle">AGENCY ADMIN</p>
          <ul className="formSidePanelLists">
            <li className="formSidePanelLink">
              <HashLink smooth to="#status" className="fomLink">
                Status
              </HashLink>
            </li>
            <li className="formSidePanelLink">
              <HashLink smooth to="#regulatedcare" className="fomLink">
                Regulated Care
              </HashLink>
            </li>
            <li className="formSidePanelLink">
              <HashLink smooth to="#riskmanagement" className="fomLink">
                Risk Management
              </HashLink>
            </li>
            <li className="formSidePanelLink">
              <HashLink smooth to="#accessibleinfostandard" className="fomLink">
                Accessible Information Standard
              </HashLink>
            </li>
            <li className="formSidePanelLink">
              <HashLink smooth to="#fundingarrangement" className="fomLink">
                Funding Arrangements
              </HashLink>
            </li>
            <li className="formSidePanelLink">
              <HashLink smooth to="#matching" className="fomLink">
                Matching
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
const AboutMePanel=(props)=> {
  const dropDwnRvl = useRef()
  const {carercom}=props;
  const stateClient=props.client;

  const [closeButtton, setCloseButton] = useState(false)
  const [view,setView]=useState("pdetails");
  const [isEdit,setIsEdit] =useState(false);
  const [client, setClient] = useState({...stateClient, contacts:[{}], professionals:[{}]});
  const [value, setValue] = useState("")
  const [contacts, setContacts] =useState([{id:0}]);
  const [professionals, setProfessional]=useState([{id:0}]);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null); // Javascript
  const [errortext, setErrortext] = useState('');
  const [formTitle, setFormTitle] = useState("Personal Details")


  var copyClient=useState({...stateClient, contacts:[{}], professionals:[{}]});
   useEffect(() => {
   console.log("refresh page");
   //onLoad();
   }, []); 

   const onLoad =() =>{
  
    Constants.getData("api/client/"+client.id, {companyid:carercom.company.id}).then(async (res)=>{
     
      console.log(res);
      setLoading(false);

      if (res.code === 0) {
         
        var cc={...res.client,
          contacts:res.contacts.length==0?[{}]:res.contacts, 
          professionals:res.professionals.length==0?[{}]:res.professionals};
        
        setClient(cc);
       

      } else {
        setErrortext(res.status);
      }
  }).catch((e)=>{
    console.log(e)
    setLoading(false);
    setErrortext("Network Issues with registration. Try again");
  });
}

  const ClinicalDetails =()=>{
    return (
      <div className="ClinicalDetails">
        <div className="innerClinicalDetails">
          <div className="partLinkWrap mBtm">
            <div className="partLinkWrapAndDesc">
              <h1 className="populateTitle">Health details</h1>
              <p className="pupulateDesc ">
                If you do not collect this information already, this section can
                be skipped
              </p>
            </div>
            <div className="addDetlsOrEdit">
              <div
                smooth
                className="plusIconAndAddDtlsTxt" onClick={onEdit}
              >
                <img src={plusIcon} alt="" className="plusIcon" />
                <p className="addDetlsTxt">Add details</p>
              </div>
            </div>
          </div>
  
          <div className="partLinkWrap mBtm">
            <div className="partLinkWrapTitleAndDesc">
              <h1 className="populateTitle">Allergies and intolerances</h1>
              <p className="pupulateDesc ">
                If you do not collect this information already, this section can
                be skipped
              </p>
            </div>
            <div className="addDetlsOrEdit" onClick={onEdit}>
              <div
                to="/ClinicalDetailsForm/#allergiesandintolerances"
                smooth
                className="plusIconAndAddDtlsTxt"
              >
                <img src={plusIcon} alt="" className="plusIcon" />
                <p className="addDetlsTxt">Add details</p>
              </div>
            </div>
          </div>
  
          <div className="partLinkWrap mBtm">
            <div className="partLinkWrapTitleAndDesc">
              <h1 className="populateTitle">Doctor/GP</h1>
              <p className="pupulateDesc ">
                If you do not collect this information already, this section can
                be skipped
              </p>
            </div>
            <div className="addDetlsOrEdit" onClick={onEdit}>
              <div
                to="/ClinicalDetailsForm/#doctor"
                smooth
                className="plusIconAndAddDtlsTxt"
              >
                <img src={plusIcon} alt="" className="plusIcon" />
                <p className="addDetlsTxt">Add details</p>
              </div>
            </div>
          </div>
  
          <div className="partLinkWrap mBtm">
            <div className="partLinkWrapTitleAndDesc">
              <h1 className="populateTitle">Pharmacist</h1>
              <p className="pupulateDesc ">
                If you do not collect this information already, this section can
                be skipped
              </p>
            </div>
            <div className="addDetlsOrEdit" onClick={onEdit}>
              <div
                
                smooth
                className="plusIconAndAddDtlsTxt"
              >
                <img src={plusIcon} alt="" className="plusIcon" />
                <p className="addDetlsTxt">Add details</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const PersonalDetails =() =>{
    return (<div className="generalDetails">
    <div >
      <div className="details" >
        <div className="header">
          <p className="headerText">Profile</p>
          <div className="addDetlsOrEdit">
              <div
                className="plusIconAndAddDtlsTxt" onClick={()=>setIsEdit(true)}
              >
                <img src={editIcon} alt="" className="plusIcon" />
                <p className="addDetlsTxt">Edit</p>
              </div>
            </div>
        </div>
        <div className="areaDetails">
          <div className="groupItem">
                <span className="itemLabel"> Title </span>
                <span
                  className="itemText"
                  style={{ color: "#05EB00" }}
                >
                  {client.title}
                </span>
              </div>
              <div className="groupItem">
                <span className="itemLabel"> First Name </span>
                <span
                  className="itemText"
                  style={{ color: "#05EB00" }}
                >
                  {client.firstName}
                </span>
              </div>
              <div className="groupItem">
                <span className="itemLabel"> Middle Name </span>
                <span
                  className="itemText"
                  style={{ color: "#05EB00" }}
                >
                  {client.middle}
                </span>
              </div>
              <div className="groupItem">
                <span className="itemLabel"> Last Name </span>
                <span
                  className="itemText"
                  style={{ color: "#05EB00" }}
                >
                  {client.lastName}
                </span>
              </div>
              <div className="groupItem">
                <span className="itemLabel"> Date of birth </span>
                <span
                  className="itemText"
                  style={{ color: "#05EB00" }}
                >
                  {client.dOB}
                </span>
              </div>
              <div className="groupItem">
                <span className="itemLabel"> status </span>
                <span className="itemText" style={ client.status == "Active" ? { color: "#05EB00" } : { color: "white" }}>
                  {client.status}
                </span>
              </div>
         
        </div>
      </div>

      <div className="details">
        <div className="header">
          <p className="headerText">Contact Details</p>
          <div className="addDetlsOrEdit">
              <div
                className="plusIconAndAddDtlsTxt"
              >
                <img src={editIcon} alt="" className="plusIcon" />
                <p className="addDetlsTxt">Edit</p>
              </div>
            </div>
        </div>
        <div className="areaDetails">
          <div className="groupItem">
                <span className="itemLabel">Primary Phone Number</span>
                <span className="itemText">{client.phone1}</span>
              </div>
            <div className="groupItem">
                <span className="itemLabel">Address</span>
                <span className="itemText">{client.address}</span>
              </div>
               <div className="groupItem">
               <span className="itemLabel">Map Location</span>
               <span className="itemText">{client.geolocation}</span>
             </div>
             <div className="groupItem">
               <span className="itemLabel">Access Details</span>
               <span className="itemText">{client.accessInfo}</span>
             </div>
         
        </div>
      </div>
      <div>
        
      </div>
    </div>
  </div>)
  }
  const PersonalIdentity=() =>{
    return (<div className="innerPersonalIdentity">
    <div className="partLinkWrap mBtm">
      <div className="theWrap">
        <h1 className="populateTitle">Sexuality</h1>
        <p className="pupulateDesc ">
          If you do not collect this information already, this section can
          be skipped
        </p>
      </div>
      <div className="addDetlsOrEdit">
        <div onClick={onEdit}
          className="plusIconAndAddDtlsTxt"
          smooth
        >
          <img src={plusIcon} alt="" className="plusIcon" />
          <p className="addDetlsTxt">Add details</p>
        </div>
      </div>
    </div>

    <div className="partLinkWrap mBtm">
      <div className="theWrap">
        <h1 className="populateTitle">Culture and relgion</h1>
        <p className="pupulateDesc">
          If you do not collect this information already, this section can
          be skipped
        </p>
      </div>
      <div className="addDetlsOrEdit"  onClick={onEdit}>
        <div
          className="plusIconAndAddDtlsTxt"
          smooth
        >
          <img src={plusIcon} alt="" className="plusIcon" />
          <p className="addDetlsTxt">Add details</p>
        </div>
      </div>
    </div>

    <div className="partLinkWrap mBtm">
      <div className="theWrap">
        <h1 className="populateTitle">Life History</h1>
        <p className="pupulateDesc ">
          If you do not collect this information already, this section can
          be skipped
        </p>
      </div>
      <div className="addDetlsOrEdit"  onClick={onEdit}>
        <div
          className="plusIconAndAddDtlsTxt"
          smooth
        >
          <img src={plusIcon} alt="" className="plusIcon" />
          <p className="addDetlsTxt">Add details</p>
        </div>
      </div>
    </div>

    <div className="partLinkWrap magrBtm">
      <div className="theWrap">
        <h1 className="populateTitle">Preferences</h1>
        <p className="pupulateDesc ">
          If you do not collect this information already, this section can
          be skipped
        </p>
      </div>
      <div className="addDetlsOrEdit"  onClick={onEdit}>
        <div
          className="plusIconAndAddDtlsTxt"
          smooth
        >
          <img src={plusIcon} alt="" className="plusIcon" />
          <p className="addDetlsTxt">Add details</p>
        </div>
      </div>
    </div>
  
</div>)
  }
  const KeyContact=() =>{
    return (
      <div className="KeyContact">
        <div className="innerKeyContact">
          <div className="partLinkWrap mBtm">
            <div className="partLinkWrapAndDesc">
              <h1 className="populateTitle">Nok/emergency Contacts</h1>
              <p className="pupulateDesc ">
                If you do not collect this information already, this section can
                be skipped
              </p>
            </div>
            <div className="addDetlsOrEdit" onClick={onEdit}>
              <div
                className="plusIconAndAddDtlsTxt" 
              >
                <img src={plusIcon} alt="" className="plusIcon" />
                <p className="addDetlsTxt">Add details</p>
              </div>
            </div>
          </div>
  
          <div className="partLinkWrap mBtm">
            <div className="partLinkWrapTitleAndDesc">
              <h1 className="populateTitle">Other Professionals</h1>
              <p className="pupulateDesc ">
                If you do not collect this information already, this section can
                be skipped
              </p>
            </div>
            <div className="addDetlsOrEdit"  onClick={onEdit}>
              <div
                className="plusIconAndAddDtlsTxt"
              >
                <img src={plusIcon} alt="" className="plusIcon" />
                <p className="addDetlsTxt">Add details</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const FuturePlanning=() =>{
    return (
      <div className="FuturePlanning">
        <div className="innerFuturePlanning">
          <div className="partLinkWrap mBtm">
            <div className="partLinkWrapAndDesc">
              <h1 className="populateTitle">Capacity and documentation</h1>
              <p className="pupulateDesc ">
                If you do not collect this information already, this section can
                be skipped
              </p>
            </div>
            <div className="addDetlsOrEdit">
              <div
                className="plusIconAndAddDtlsTxt"
              >
                <img src={plusIcon} alt="" className="plusIcon" />
                <p className="addDetlsTxt">Add details</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const AgencyAdmin=() =>{
    return (
      <div className="AgencyAdmin">
        <div className="innerAgencyAdmin">
          <div className="partLinkWrap mBtm">
            <div className="partLinkWrapAndDesc">
              <h1 className="populateTitle">Status</h1>
              <p className="pupulateDesc ">
                If you do not collect this information already, this section can
                be skipped
              </p>
            </div>
            <div className="addDetlsOrEdit">
              <div
                className="plusIconAndAddDtlsTxt"
              >
                <img src={plusIcon} alt="" className="plusIcon" />
                <p className="addDetlsTxt">Add details</p>
              </div>
            </div>
          </div>
  
          <div className="partLinkWrap mBtm">
            <div className="partLinkWrapTitleAndDesc">
              <h1 className="populateTitle">Regulated Care</h1>
              <p className="pupulateDesc">
                If you do not collect this information already, this section can
                be skipped
              </p>
            </div>
            <div className="addDetlsOrEdit">
              <div
                className="plusIconAndAddDtlsTxt"
              >
                <img src={plusIcon} alt="" className="plusIcon" />
                <p className="addDetlsTxt">Add details</p>
              </div>
            </div>
          </div>
  
          <div className="partLinkWrap mBtm">
            <div className="partLinkWrapTitleAndDesc">
              <h1 className="populateTitle">Risk Management</h1>
              <p className="pupulateDesc">
                If you do not collect this information already, this section can
                be skipped
              </p>
            </div>
            <div className="addDetlsOrEdit">
              <div
                className="plusIconAndAddDtlsTxt"
              >
                <img src={plusIcon} alt="" className="plusIcon" />
                <p className="addDetlsTxt">Add details</p>
              </div>
            </div>
          </div>
  
          <div className="partLinkWrap mBtm">
            <div className="partLinkWrapTitleAndDesc">
              <h1 className="populateTitle">Accessible Information Standard</h1>
              <p className="pupulateDesc">
                If you do not collect this information already, this section can
                be skipped
              </p>
            </div>
            <div className="addDetlsOrEdit">
              <div
                className="plusIconAndAddDtlsTxt"
              >
                <img src={plusIcon} alt="" className="plusIcon" />
                <p className="addDetlsTxt">Add details</p>
              </div>
            </div>
          </div>
  
          <div className="partLinkWrap mBtm">
            <div className="partLinkWrapTitleAndDesc">
              <h1 className="populateTitle">Funding Arrangement</h1>
              <p className="pupulateDesc">
                If you do not collect this information already, this section can
                be skipped
              </p>
            </div>
            <div className="addDetlsOrEdit">
              <div
                className="plusIconAndAddDtlsTxt"
              >
                <img src={plusIcon} alt="" className="plusIcon" />
                <p className="addDetlsTxt">Add details</p>
              </div>
            </div>
          </div>
  
          <div className="partLinkWrap mBtm">
            <div className="partLinkWrapTitleAndDesc">
              <h1 className="populateTitle">Matching</h1>
              <p className="pupulateDesc">
                If you do not collect this information already, this section can
                be skipped
              </p>
            </div>
            <div className="addDetlsOrEdit">
              <div
                className="plusIconAndAddDtlsTxt"
              >
                <img src={plusIcon} alt="" className="plusIcon" />
                <p className="addDetlsTxt">Add details</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

 

 

 

  const handleDropDwnRvl = () => {
    dropDwnRvl.current.classList.toggle("DISPLAYNone")
    setCloseButton((prev) => !prev)
  }
const onCancel=()=>{
setClient(copyClient);
setIsEdit(false);
}
const onEdit=()=>{
  setIsEdit(true);
  copyClient =client;
}
const onSave=()=>{
  copyClient=client;
  setIsEdit(false);
}
const onNext=() =>{

}
const onPrev=() =>{
  
}

const syncContactlist= (contacts) =>{
  var count=1;
  contacts.forEach(element => {
    element.sn=count;
    count++;
   });

   return contacts;
}



  return (
    <div className="RightMainPanel">
      <div className="innerRightMainPanel">
       <div className="aboutMePanel">
      <div className="innerAboutMePanel">
        <nav className="aboutMeNavBar">
          <ul className="aboutMeNavBarLinkWrap" ref={dropDwnRvl}>
            <li className="aboutMeLinks">
              <span  className={(view=='pdetails')?'fakeLinkselected':'fakeLink'}   onClick={()=>setView("pdetails")}>Personal details</span>
             
            </li>
            <li className="aboutMeLinks">
            <span className={(view=='pidentity')?'fakeLinkselected':'fakeLink'}  onClick={()=>setView("pidentity")}>Personal Identity</span> 
            </li>
            <li className="aboutMeLinks">
            <span className={(view=='cdetails')?'fakeLinkselected':'fakeLink'}  onClick={()=>setView("cdetails")}>Clinical details</span>  
            </li>
            <li className="aboutMeLinks">
            <span className={(view=='kcontact')?'fakeLinkselected':'fakeLink'} onClick={()=>setView("kcontact")}>Key contacts</span>   
            </li>
            <li className="aboutMeLinks">
              <span className={(view=='fplanning')?'fakeLinkselected':'fakeLink'} onClick={()=>setView("fplanning")}> Future planning</span>  
            </li>
            <li className="aboutMeLinks"> 
              <span className={(view=='aadmin')?'fakeLinkselected':'fakeLink'}  onClick={()=>setView("aadmin")}> Agency admin</span>  
            </li>
          </ul>

          <div className="dropDwnMenu" onClick={handleDropDwnRvl}>
            {closeButtton ? (
              <img src={dropDownIcon} alt="" className="dropDwnIcon" />
            ) : (
              <img src={dropUpIcon} alt="" className="dropDwnIcon" />
            )}
          </div>
        </nav>

        <div className="aboutMeLinkRoutes">
        {view=="pdetails" && !isEdit?<PersonalDetails />:null}
        {view=="pidentity" && !isEdit?<PersonalIdentity/>  :null}
        {view=="cdetails" && !isEdit?<ClinicalDetails />:null}
        {view=="kcontact" && !isEdit?<KeyContact/>:null}
        {view=="fplanning" && !isEdit?<FuturePlanning />:null}
        {view=="aadmin" && !isEdit?<AgencyAdmin />:null}

        {view=="pdetails" && isEdit?<PersonalDetailForm  client={client} setClient={setClient} setFormTitle={setFormTitle} />:null}
        {view=="pidentity" && isEdit?<PersonalIdentityForm  client={client} setClient={setClient} setFormTitle={setFormTitle}  />:null}
        {view=="cdetails" && isEdit?<ClinicalDetailsForm  client={client} setClient={setClient} setFormTitle={setFormTitle}  />:null}
        {view=="kcontact" && isEdit?<KeyContactForm   client={client} setClient={setClient}  setFormTitle={setFormTitle} />:null}
        {view=="fplanning" && isEdit?<FuturePlanningForm   client={client} setClient={setClient}  setFormTitle={setFormTitle} />:null}
        {view=="aadmin" && isEdit?<AgencyAdminForm   client={client} setClient={setClient}  setFormTitle={setFormTitle} />:null}
        {isEdit?<EditForm title={formTitle} onCancel={onCancel} onSave={onSave} />:null}
        </div>
      </div>
    </div>
      </div>
    </div>
    
  )
}

export default AboutMePanel
