import "./WeCareMessage.scss"
import React from "react"
import WeCareMessageHead from "./WeCareMessageHead"
import WeCareMessageBody from "./WeCareMessageBody"

function WeCareMessage() {
  return (
    <div className="WeCareMessage">
      <div className="innerWeCareMessage">
        <WeCareMessageHead />
        <WeCareMessageBody />
      </div>
    </div>
  )
}

export default WeCareMessage
