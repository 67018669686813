import React, { useState } from "react"
import "./SixSection.scss"
import image from "../assets/star.svg"
import arrowDwn from "../assets/angle-down.svg"
import arrowRightLong from "../assets/arrow-right-long.svg"
import sixSecData from "./SixSecData"
import { Link } from "react-router-dom"

function SixSection() {
  const [secHeight, setSecHeight] = useState(30)

  const varrible = sixSecData.length * 4.5
  const handleSeeMore = (e) => {
    e.preventDefault()
    setSecHeight((prev) => (prev < 100 - varrible ? prev + 15 : 30))
  }
  return (
    <section>
      <div className="innerSixSection">
        <div className="excellentWrap">
          <div className="star">
            <img src={image} alt="" className="starRate" />
            <img src={image} alt="" className="starRate" />
            <img src={image} alt="" className="starRate" />
            <img src={image} alt="" className="starRate" />
            <img src={image} alt="" className="starRate" />
          </div>
          <h1 className="excellentHeadText">excellent</h1>
          <p className="rateText">Rated 4.7 / 5 based on 182 reviews</p>
        </div>

        <div className="listWrap" style={{ height: `${secHeight + 10}vh` }}>
          {sixSecData.map((item) => {
            return (
              <div className="list" key={item.id}>
                <p className="rateName">{item.rateName}</p>
                <p className="rateParagraph">{item.rateParagraph}</p>
                {/* <img src={arrowDwn} alt="" className="arrowDwn" /> */}
              </div>
            )
          })}
        </div>

        <div className="SeeMore" onClick={handleSeeMore}>
          <p className="seeMoreText">
            {secHeight > 100 - varrible ? "Collapse" : "See more"}
          </p>
          <img src={arrowRightLong} alt="" className="arrowRightLong" />
        </div>
      </div>
    </section>
  )
}

export default SixSection
