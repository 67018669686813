import "./DashboardHeader.scss"
import React, { useState, useRef } from "react"
import { Link } from "react-router-dom"
import logoImage from "../assets/wecareLogo.png"
import accImage from "../assets/pexels-tima-miroshnichenko-8376187.jpg"
import share from "../assets/share-svgrepo-com.svg"
import logOut from "../assets/logout-svgrepo-com (1).svg"
import settings from "../assets/setting-svgrepo-com.svg"

function DashboardHeader() {
  /* const [borderData, setBorderData] = useState()
  const [careerBottomBorder, setCareerBottomBorder] = useState(false)
  const [clientBottomBorder, setClientBottomBorder] = useState(false)
  const [messageBottomBorder, setMessageBottomBorder] = useState(false)

  const setBorder = (setMethod) => {
    const newData = setMethod((prev) => !prev)
    setBorderData(newData)
  } */

  const menuBtn = useRef()
  const menuBtn2 = useRef()
  const closeMenu = useRef()

  const handleMenuBtn = () => {
    menuBtn.current.classList.toggle("displaYNone")
    menuBtn2.current.classList.toggle("displayNone")
    closeMenu.current.classList.toggle("close")
  }

  return (
    <div className="DashboardHeader">
      <div className="dashboardInnerHeader">
        <div className="logoAndAccName">
          <img src={logoImage} alt="logo" className="weCareLogo" />
          <div className="avatarAccName">
            <div className="accNameOutline">
              <img src={accImage} alt="" className="accAvatar" />
            </div>
            <div className="nameSyncTime">
              <p className="accName">Onaagbaa Healthcare services</p>
              <p className="syncTime">Last sync 9 mins ago</p>
            </div>
          </div>
        </div>
        <div className="navAndTools">
          <div className="tools">
            <img src={share} alt="" className="shareImage toolImg" />
            <img src={logOut} alt="" className="logoOutImage toolImg" />
            <img src={settings} alt="" className="settingImage toolImg" />
          </div>
          <nav className="dashboardNav">
            <ul className="dashboardListWrap displayNone" ref={menuBtn2}>
              <li className="dashboardNavLink">
                <Link className="dshBrdLnk"> Careers</Link>
              </li>
              <li className="dashboardNavLink">
                <Link className="dshBrdLnk"> Clients</Link>
              </li>
              <li className="dashboardNavLink">
                <Link className="dshBrdLnk"> Messages</Link>
              </li>
            </ul>

            <div
              ref={closeMenu}
              className="dashboardMenu"
              onClick={handleMenuBtn}
            >
              <div className="menuLine"></div>
              <div className="menuLine"></div>
              <div className="menuLine"></div>
            </div>
          </nav>

          <div className="menuList displaYNone" ref={menuBtn}>
            <ul className="menuListLinks">
              <li className="menuListLink">
                <Link className="dashHeadMnLnk">Share</Link>
              </li>
              <li className="menuListLink">
                <Link className="dashHeadMnLnk">Settings</Link>
              </li>
              <li className="menuListLink">
                <Link className="dashHeadMnLnk">Log Out</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardHeader
