//export const root_url="https://localhost:7273/";
export const root_url="https://wecareapi.justwebtech.com/";
const appVersion="3.2";
const os="web";
const osVersion= "0.0";
const deviceIp="1.1.1.1";
const password = 'Justwebtech 37-1';
var md5 = require('md5');
export const getMd5Hash = authone => {
    console.log('md5 hash: ' + md5(authone));
    return  md5(authone);
    // return authone;
  };
  export const TimeStamp = () => {
    return new Date().getTime();
  };

  const getheader =(hh) =>{
    let ms=new Date().toISOString();
    let ms2=Date.parse(ms);
    let timestamp=parseInt(ms2/1000);
    let memberid=hh.memberid === undefined?0:hh.memberid;
   // let toash=
    let hash= getMd5Hash(timestamp+memberid);
    let hder={deviceIp,appVersion,os,osVersion,timestamp,hash};
    return hder;
  }
    export const getData = async (url, header) => {
       let h= getheader(header);
      header={...header, ...h };
      console.log(url);
      console.log(header)
  
          const requestOptions = {
              method: 'GET',
              headers: {  ...header, 'Content-Type': 'application/json' },           
          };
          const response = await fetch(root_url+url, requestOptions);
          const res = await response.json();
          return res;
    
    };
    export const deleteData = async (url, header) => {
      let h= getheader(header);
      header={...header, ...h };
      console.log(url);
      console.log(header)
  
          const requestOptions = {
              method: 'DELETE',
              headers: {  ...header, 'Content-Type': 'application/json' },           
          };
          const response = await fetch(root_url+url, requestOptions);
          const res = await response.json();
          return res;
    
    };
    export const postData = async (url, data, header) => {
      let h= getheader(header);
      header={...header, ...h };
      console.log(url);
      var strdata=JSON.stringify(data)
      console.log(strdata);
      console.log(header)
  
          const requestOptions = {
              method: 'POST',
              headers: { ...header, 'Content-Type': 'application/json' },
              body: strdata
          };
          const response = await fetch(root_url+url, requestOptions);
         // console.log(response);
          const res = await response.json();
        //  console.log(res);
          return res;
      
    };
    export const putData = async (url, data, header) => {
      let h= getheader(header);
      header={...header, ...h };
      console.log(url);
      var strdata=JSON.stringify(data)
      console.log(strdata);
          const requestOptions = {
              method: 'PUT',
              headers: { ...header, 'Content-Type': 'application/json' },
              body: strdata
          };
          const response = await fetch(root_url+url, requestOptions);
         // console.log(response);
          const res = await response.json();
          return res;
      /* } catch (e) {
        // error reading value
        console.log('error from getData:');
        console.log(e);
        
      } */
    };
    export const firstChar=(value)=>{
       return Array.from(value)[0];
    }