import "./ClientBoardBody.scss"
import React, { useRef, useState, useEffect } from "react"
import ClientBoardData from "./clientBoardData"
import { useNavigate } from "react-router-dom";
import * as Constants from "../../Constants";

const ClientBoardBody =(props)=> {
  const navigate = useNavigate();
  const {carercom}=props;
  const {filter}=props; //for searching
  const [loading, setLoading] = useState(false);
  const [clientlist, setClientList]=useState([]);
  const [clientlist_server, setClientList_Server]=useState([]);
  const [errortext, setErrortext] = useState('');
  const onDetails =(item) =>{
    navigate("/dashboard",{state:{carercom,client:item}});
  }
  useEffect(() => {
   //load company names
   
   console.log('in clientboardbody**********************')
  
   onLoad();
   }, [carercom]); 
   useEffect(() => {
    //load company names
    
    console.log('on change ***********************')
    if(filter==''){
      setClientList(clientlist_server); //reset it all
    }else{
      //.filter()
      var filterlist=clientlist_server.filter(c =>  (c.firstName.toLowerCase().includes(filter.toLowerCase()) || c.firstName.toLowerCase().includes(filter.toLowerCase())) ) ;
      setClientList(filterlist);
    }
  
    }, [filter]); 

   const onLoad =() =>{
  
    Constants.getData("api/client", {companyid:carercom.company.id}).then(async (res)=>{
     
      console.log(res);
      setLoading(false);

      if (res.code === 0) {
         
       setClientList(res.clients);
       setClientList_Server(res.clients);

      } else {
        setErrortext(res.status);
      }
  }).catch((e)=>{
    console.log(e)
    setLoading(false);
    setErrortext("Network Issues with registration. Try again");
  });
}
  return (
    <div className="ClientBoardBody">
      <div className="innerClientBoardBody">
        {clientlist.map((client, indx) => (
          <div className="cellFirst" onClick={()=>onDetails(client)} key={indx}>
            <div className="accProf">
              <div className="accProfPix">
                <p className="accProNam"> {Constants.firstChar(client.firstName) }{Constants.firstChar(client.lastName)}</p>
              </div>
              <div className="nameAndState">
                <p className="clientBdName">{client.firstName} {client.lastName} </p>
                <p className="clientBdState">{client.status}</p>
              </div>
            </div>

            <div className="riskLevelAndGrp">
              <p className="riskLvlText">
               <span>Risk Level</span>  <span className="stateLvl">{client.risk}</span>
              </p>

              <div className="grup">
                
                <p className="clientGrpName">{client.locationName}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ClientBoardBody
