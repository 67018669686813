import React from "react"
import "./FourthSection.scss"
import image from "../assets/pexels-tima-miroshnichenko-8376258.jpg"

function FourthSection() {
  return (
    <section>
      <div className="innerFourthSection">
        <div className="pictureHead">
          <h1 className="getYourResult">
            Get your results in just five minutes
          </h1>
          <img src={image} alt="" className="headPix" />
        </div>

        <div className="textAreaWrap">
          <p className="reportText">*Taken from State of Tech (2022) report.</p>

          <h1 className="percentSaves">
            73% of We Care partners save 7-15 hours a week on day to day
            operations*
          </h1>

          <p className="percentSavesParagraph">
            We Care team has developed a cost-saving calculator that allows your
            care business to estimate potential savings by adopting home
            healthcare technology, which could be significant!
          </p>
          <div className="btnWrap">
            <button type="button" className="saveCalcBtn">
              Try the cost saving calculator
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FourthSection
