import "./ClientBoardHeader.scss"
import React, { useRef } from "react"
import { Link } from "react-router-dom"
import logo from "../assets/wecareLogo.png"
import syncImg from "../assets/sync-svgrepo-com.svg"
import expand from "../assets/expand-more-alt-svgrepo-com.svg"

function ClientBoardHeader() {
  const navRef = useRef()
  const secondNavRef = useRef()
  const menuRef = useRef()

  const showNavbar = () => {
    navRef.current.classList.toggle("displayNone")
    secondNavRef.current.classList.toggle("displayNone")
    menuRef.current.classList.toggle("close")
  }
  return (
    <div className="ClientBoardHeader">
      <div className="innerClientBoardHeader">
        <div className="clientHeaders">
          <nav className="clientNav">
            <img src={logo} alt="" className="logoImg" />
            <ul className="clientNavList displayNone" ref={navRef}>
              <li className="clientLink">
                <Link>Clients</Link>
              </li>
              <li className="clientLink">
                <Link>Teams</Link>
              </li>
              <li className="clientLink">
                <Link>Inbox</Link>
              </li>
              <li className="clientLink">
                <Link>Log</Link>
              </li>
            </ul>
          </nav>
          <div className="notifyAndAcc displayNone" ref={secondNavRef}>
            <div className="notify">
              <p className="syncMins">15</p>
              <img src={syncImg} alt="" className="syncImg" />
              <p className="syncMin">15mins ago</p>
            </div>

            <div className="clientAccName">
              <p className="acName">OS</p>
              <img src={expand} alt="" className="expand" />
            </div>
          </div>

          <div className="clientBoardMenu" ref={menuRef} onClick={showNavbar}>
            <div className="clientBoardMenuLine"></div>
            <div className="clientBoardMenuLine"></div>
            <div className="clientBoardMenuLine"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientBoardHeader
