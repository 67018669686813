const fifthSecData = [
  {
    id: 1,
    BizName: "Key2Care",
    BizTestimony:
      "How Key2Care delivers personalised care to 400 unique care recipients",
  },
  {
    id: 2,
    BizName: "Key2Care",
    BizTestimony:
      "How Key2Care delivers personalised care to 400 unique care recipients",
  },
  {
    id: 3,
    BizName: "Key2Care",
    BizTestimony:
      "How Key2Care delivers personalised care to 400 unique care recipients",
  },
  {
    id: 4,
    BizName: "Key2Care",
    BizTestimony:
      "How Key2Care delivers personalised care to 400 unique care recipients",
  },
  {
    id: 5,
    BizName: "Key2Care",
    BizTestimony:
      "How Key2Care delivers personalised care to 400 unique care recipients",
  },
]

export default fifthSecData
