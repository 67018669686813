const InitialAssessmentsData = [
  {
    listImage: "img",
    listTitleHead: "Personal Care",
    listTitleDesc: "updated 3 Mar, 2023",
  },
  {
    listImage: "img",
    listTitleHead: "Personal Care",
    listTitleDesc: "updated 3 Mar, 2023",
  },
  {
    listImage: "img",
    listTitleHead: "Personal Care",
    listTitleDesc: "updated 3 Mar, 2023",
  },
  {
    listImage: "img",
    listTitleHead: "Personal Care",
    listTitleDesc: "updated 3 Mar, 2023",
  },
  {
    listImage: "img",
    listTitleHead: "Personal Care",
    listTitleDesc: "updated 3 Mar, 2023",
  },
  {
    listImage: "img",
    listTitleHead: "Personal Care",
    listTitleDesc: "updated 3 Mar, 2023",
  },
  {
    listImage: "img",
    listTitleHead: "Personal Care",
    listTitleDesc: "updated 3 Mar, 2023",
  },
  {
    listImage: "img",
    listTitleHead: "Personal Care",
    listTitleDesc: "updated 3 Mar, 2023",
  },
]

export default InitialAssessmentsData
