import React, { useState } from "react"
import "./Dashboard.scss"
import DashboardHeader from "../headers/DashboardHeader"
import ClientBoardHeader from "../headers/ClientBoardHeader"
import DashboardSidebar from "../sidebars/DashboardSidebar"
import {useLocation} from 'react-router-dom';

import AboutMePanel from "./AboutMePanel"

function Dashboard(props) {

  const location = useLocation();
  const {carercom,client}=location.state;
  
  
  return (
    <main className="dashBoard">
      <div className="innerMain">
        {/* <ClientBoardHeader carercom={carercom} client={client} /> */}
        <DashboardHeader   carercom={carercom} client={client}  />
        <div className="sideBarAndRightBar">
          <DashboardSidebar  carercom={carercom} client={client} />
          <AboutMePanel  carercom={carercom} client={client} />
        </div>
      </div>
    </main>
  )
}

export default Dashboard
