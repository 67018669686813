   
   
import React, { useState, useEffect  } from 'react';
import radio_checked from "../assets/radio_button_checked.svg"
import radio_unchecked from "../assets/radio_button_unchecked.svg"
import checked from "../assets/check_box.svg";
import unchecked from "../assets/check_box_outline_blank.svg";

const Phone = (props) => {
    //const trans =props.route.params.obj;
const {onSet}=props;

  return (
    <div className="utilWrap">
    <select name="phone" className="phone">
      <option value="ZZ">INT</option>
      <option value="NG">NIG</option>
      <option value="US">USA</option>
      <option value="EN">ENG</option>
      <option value="SC">SCO</option>
      <option value="IT">ITA</option>
      <option value="GH">GHA</option>
      <option value="SA">SAF</option>
      <option value="TG">TOG</option>
      <option value="CH">CHI</option>
      <option value="BR">BRA</option>
      <option value="KE">KEN</option>
      <option value="FR">FRA</option>
      <option value="ES">ESP</option>
    </select>
    <input
        onChange={(e)=> onSet(e.target.value) } value={props.value}
      type="number"
      className="firstNameInpt"
      placeholder="Other Number"
    />
  </div>
  );
  }
  
  export default Phone;
  