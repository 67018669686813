const sixSecData = [
  {
    id: 1,
    rateName: "Edwin masunda,9 April",
    rateParagraph: "Work made easy using We-Care",
  },
  {
    id: 2,
    rateName: "Edwin masunda,9 April",
    rateParagraph: "Work made easy using We-Care",
  },
  {
    id: 3,
    rateName: "Edwin masunda,9 April",
    rateParagraph: "Work made easy using We-Care",
  },
  {
    id: 4,
    rateName: "Edwin masunda,9 April",
    rateParagraph: "Work made easy using We-Care",
  },
  {
    id: 5,
    rateName: "Edwin masunda,9 April",
    rateParagraph: "Work made easy using We-Care",
  },
  {
    id: 6,
    rateName: "Edwin masunda,9 April",
    rateParagraph: "Work made easy using We-Care",
  },
  {
    id: 7,
    rateName: "Edwin masunda,9 April",
    rateParagraph: "Work made easy using We-Care",
  },
  {
    id: 8,
    rateName: "Edwin masunda,9 April",
    rateParagraph: "Work made easy using We-Care",
  },
  {
    id: 9,
    rateName: "Edwin masunda,9 April",
    rateParagraph: "Work made easy using We-Care",
  },
  {
    id: 10,
    rateName: "Edwin masunda,9 April",
    rateParagraph: "Work made easy using We-Care",
  },
  {
    id: 11,
    rateName: "Edwin masunda,9 April",
    rateParagraph: "Work made easy using We-Care",
  },
]

export default sixSecData
