import React, { useState, useEffect  } from 'react';

const ActionButtons =(props)=>{
    const {onCancel}=props;
   
    const {onSave} =props;
    var {title}=props;
    return (<div className="EditForm">
    <div className="innerEditForm">
     
  
      <footer className="dashBrdFt">
        <div className="innerDashbrdft">
          <button type="button" className="cancelBtn" onClick={onCancel}>
            <span className="cancleBtnn">
              {props.cancel}
            </span>
          </button>
          <div className="nextFromSection" >
            <p className="nextTabDshBrd"></p>
           
          </div>
          <button type="submit" className="saveBtn" onClick={onSave}>
            {props.save}
          </button>
        </div>
      </footer>
    </div>
  </div>)
  }

  export default ActionButtons;