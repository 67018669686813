import React from "react"
import "./SeventhSection.scss"
import image from "../assets/pexels-tima-miroshnichenko-8376187.jpg"

export default function SeventhSection() {
  return (
    <section>
      <div className="innerSeventhSection">
        <div className="circlePictureWrap">
          <div className="firstCircle">
            <img src={image} alt="" className="seventhHeroPicture" />
          </div>
          <div className="circlesSeventh"></div>
          <div className="circlesSeventh2"></div>
        </div>

        <div className="seventhTextSection">
          <h2 className="weCanHelp">we can help you</h2>
          <h3 className="letsShowYou">Let us show you how we care can help</h3>
          <p className="seventhTextSectionParagraph">
            As an expert, you deserve home healthcare technology that inspires
            your team and fosters your business growth.
          </p>
          <div className="btnSeventhSec">
            <button className="bookFreeBtn">Book a free Demo</button>
          </div>
        </div>
      </div>
    </section>
  )
}
