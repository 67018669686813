import React from "react"
import { useState } from "react"

import "../Dashboard.scss"
import DashboardHeader from "../../headers/DashboardHeader"
import ClientBoardHeader from "../../headers/ClientBoardHeader"
import DashboardSidebar from "../../sidebars/DashboardSidebar"
import { HashLink } from "react-router-hash-link"
import RadioOption from "../../components/radioOption"
import ButtonSwitch from "../../components/buttonSwitch"
import CheckOption from "../../components/checkOption"
import ActionButtons from "../../components/actionbuttons"


const FormDetails =(props) =>{
    const client=props.client;
    const {setClient}=props;
   const isAssessment=props.isAssessment;
   const setIsAssessment=props.setIsAssessment;

    const onStartAss=()=>{
        setIsAssessment(true);
    }
    

    return (
      <div className="DetailsForm">
        <div className="innerForm">
          <div className="IDForm mBtm">
            <div id="needs" className="psdtlHead mBtm">Needs assessment</div>
            <p className="IDQues">Record Adams 's level of independence for each personal care activity, and any support that is required</p>

            <button className="saveBtn" onClick={onStartAss}>Start Assessment</button>
            <p  className="subTitleHead boxline">Previous assessments</p>
            <div>
                <div>Status (In Progress)</div>
                <div>Date last update</div>
            </div>
            <div className="mBtm">

            </div>
            <div id="summary" className="psdtlHead mBtm">Assessment summary and outcomes</div>
            <p>Describe how your team can support Adams</p>
            <textarea  className="textAreaDashBrd" />
            <div>0/3000 characters</div>
           

            <button className="saveBtn mBtm">Save Summary</button>

            <div id="task" className="psdtlHead">Task</div>
            <p className="IDQues">Add tasks to support Adams in achieving their outcomes.</p>
            <div className="mBtm">
                <input placeholder="search task" className="IDInput" />
            </div>

            <div id="risk" className="psdtlHead mBtm">Risk</div>
            <p className="IDQues">Record any risks Adams presents with, and the measures taken to mitigate them. If you feel Adams ’s risk level has changed, you should consider the need to review the Care Plan and RAG status in birdie and update accordingly.</p>
            <button className="saveBtn">Add New Risk</button>
          </div>
  
          <div className="formSidePanel">
            <p className="formSidePanelTitle">Links</p>
            <ul className="formSidePanelLists">
              <li className="formSidePanelLink">
                <HashLink smooth to="#needs" className="fomLink">
                  Need
                </HashLink>
              </li>
              <li className="formSidePanelLink">
                <HashLink
                  smooth
                  to="#summary"
                  className="fomLink"
                >
                  Summary
                </HashLink>
              </li>
              <li className="formSidePanelLink">
                <HashLink smooth to="#task" className="fomLink">
                  Task
                </HashLink>
              </li>
              <li className="formSidePanelLink">
                <HashLink smooth to="#risk" className="fomLink">
                  Risk
                </HashLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
const FormItem =(props) =>{
    const client=props.client;
    const {setClient}=props;
    const isAssessment=props.isAssessment;
   const setIsAssessment=props.setIsAssessment;

    const onSaveAssessment=()=>{
        setIsAssessment(false);
    }
    return (
      <div className="DetailsForm">
        <div className="innerForm">
          <form action="" className="IDForm">
            <div className="FrmInGrps">
             
              <h2 className=" psdtlHead" id="bathing" >
              Bathing
              </h2>

              <div className="IdGrpQues">
                <label className="IDQues">Can Adams wash themself?</label>
                <div className="options">
                <RadioOption type="radio" checked={false} title="Yes, independently (with or without equipment)" />
                <RadioOption type="radio" checked={false} title="Yes, with help" />
                <RadioOption type="radio" checked={false} title="No, fully dependent" />
                </div>
                
              </div>
              <div className="IdGrpQues">
                <label className="IDQues">Additional details </label>
                <textarea  className="textAreaDashBrd"/>
  
               
              </div>
              <h2 className=" psdtlHead" id="oral" >
              Oral hygiene
              </h2>

              <div className="IdGrpQues">
                <label className="IDQues">Can Adams maintain oral hygiene?</label>
                <div className="options">
                <RadioOption type="radio" checked={false} title="Yes, independently (with or without equipment)" />
                <RadioOption type="radio" checked={false} title="Yes, with help" />
                <RadioOption type="radio" checked={false} title="No, fully dependent" />
                </div>
                
              </div>
              <div className="IdGrpQues">
                <label className="IDQues">Additional details </label>
                <textarea  className="textAreaDashBrd"/>
  
               
              </div>
              <div className="IdGrpQues">
                <label className="subTitleHead">Does Adams wear dentures</label>
                <ButtonSwitch yesTitle="Yes" noTitle="No" value={1} ismaybe={false} />
                <div className="clinicMedSup">
                  
                </div>
              </div>
              <div className="IdGrpQues">
                <label className="IDQues">Additional details </label>
                <textarea  className="textAreaDashBrd"/>
  
               
              </div>
            </div>
  
            <div className="IdGrpQues" id="personal">
             
              <label className="subTitleHead" >Personal appearance</label>
              <div>
                <label className="IDQues">
              Can Adams maintain their personal appearance?
                </label>
                <div className="options">
                <RadioOption type="radio" checked={false} title="Yes, independently (with or without equipment)" />
                <RadioOption type="radio" checked={false} title="Yes, with help" />
                <RadioOption type="radio" checked={false} title="No, fully dependent" />
                </div>
              </div>
              
  
            </div>
            <div className="IdGrpQues">
                <label className="IDQues">Additional details </label>
                <textarea  className="textAreaDashBrd"/>
  
               
              </div>

            <div className="IdGrpQues">
            
            <div>
              <label className="IDQues">Can Adams dress themself?</label>
              <div className="options">
                <RadioOption type="radio" checked={false} title="Yes, independently (with or without equipment)" />
                <RadioOption type="radio" checked={false} title="Yes, with help" />
                <RadioOption type="radio" checked={false} title="No, fully dependent" />
              </div>
            </div>
            <div className="IdGrpQues">
                <label className="IDQues">Additional details </label>
                <textarea  className="textAreaDashBrd"/>
  
               
              </div>
              
              
             
            </div>
  
            <div className="IdGrpQues magrBtm">
             
              <label className="subTitleHead" id="toilet">Toilet</label>
  
              <div>
                <label className="IDQues">Can Adams toilet themself?</label>
                <div className="options">
                <RadioOption type="radio" checked={false} title="Yes, independently (with or without equipment)" />
                <RadioOption type="radio" checked={false} title="Yes, with help" />
                <RadioOption type="radio" checked={false} title="No, fully dependent" />
              </div>
              </div>
              <div className="IdGrpQues">
                <label className="IDQues">Additional details </label>
                <textarea  className="textAreaDashBrd"/>
  
               
              </div>

              <div>
                <label className="IDQues">Does Adams have control over their bowels?</label>
                <div className="options">
                <RadioOption type="radio" checked={false} title="Continent" />
                <RadioOption type="radio" checked={false} title="Occasional accident" />
                <RadioOption type="radio" checked={false} title="Incontinent" />
              </div>
              </div>
              <div className="IdGrpQues">
                <label className="IDQues">Additional details </label>
                <textarea  className="textAreaDashBrd"/>
  
               
              </div>



              <div>
                <label className="IDQues">Does Adams have control over their bladder?</label>
                <div className="options">
                <RadioOption type="radio" checked={false} title="Continent" />
                <RadioOption type="radio" checked={false} title="Occasional accident" />
                <RadioOption type="radio" checked={false} title="Incontinent" />
              </div>
              </div>
              <div className="IdGrpQues">
                <label className="IDQues">Additional details </label>
                <textarea  className="textAreaDashBrd"/>
  
               
              </div>

              <div>
                <label className="IDQues">Does Adams have control over their bladder?</label>
                <div className="options">
                <RadioOption type="radio" checked={false} title="Continent" />
                <RadioOption type="radio" checked={false} title="Occasional accident" />
                <RadioOption type="radio" checked={false} title="Incontinent" />
              </div>
              </div>
              <div className="IdGrpQues">
                <label className="IDQues">Additional details </label>
                <textarea  className="textAreaDashBrd"/>
              </div>

              <div>
                <label className="IDQues">Does Adams need support with the following?</label>
                <div className="options">
                <RadioOption type="check" title="Catheter" checked={false} />
                <RadioOption  type="check" title="Incontinence pad" checked={false} />
                <RadioOption  type="check" title="Stoma bag" checked={false} />
                <RadioOption  type="check" title="Other (please specify)" checked={false} />
              </div>
              </div>
              <div className="IdGrpQues mBtm">
                <label className="IDQues">Additional details </label>
                <textarea  className="textAreaDashBrd mBtm"/>
              </div>

            </div>
  <ActionButtons cancel="Cancel Assessment" save="Save Assessment" onCancel={()=>console.log('cancel')} onSave={()=>console.log('save')} />
          </form>
  
          <div className="formSidePanel">
            <p className="formSidePanelTitle">Clinical Details</p>
            
            <ul className="formSidePanelLists">
              <li className="formSidePanelLink">
                <HashLink smooth to="#bathing" className="fomLink">
                  Bathing
                </HashLink>
              </li>
              <li className="formSidePanelLink">
                <HashLink
                  smooth
                  to="#oral"
                  className="fomLink"
                >
                  Oral hygiene
                </HashLink>
              </li>
              <li className="formSidePanelLink">
                <HashLink smooth to="#personal" className="fomLink">
                  Personal Care
                </HashLink>
              </li>
              <li className="formSidePanelLink">
                <HashLink smooth to="#toilet" className="fomLink">
                  Toilet
                </HashLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }

function PersonalCare(props) {
    const [isAssessment,setIsAssessment]=useState(false);
  return (
    <main className="dashBoard">
      <div className="innerMain">
        <ClientBoardHeader />
        <DashboardHeader />
        <div className="sideBarAndRightBar">
          <DashboardSidebar />
          <div className="RightMainPanel">
               {isAssessment?<FormItem setIsAssessment={setIsAssessment} />:<FormDetails  setIsAssessment={setIsAssessment}/>} 
          </div>
        </div>
      </div>
    </main>
  )
}

export default PersonalCare
