
   
   
import React, { useState, useEffect  } from 'react';

const ButtonSwitch = (props) => {
    //const trans =props.route.params.obj;
//0 nothing selected, 1=yes, no=2, 3= maybe

  return (
    
             <div className="currentStatusBtns">
             <button type="button" className={props.value==1?"prefferedBtnsSelected":"dashboardCurrentStatusBtn"} onClick={()=>props.onSelected(1)}>
             {props.yesTitle}
             </button>
             <button type="button" className={props.value==2?"prefferedBtnsSelected":"dashboardCurrentStatusBtn"} onClick={()=>props.onSelected(2)}>
             {props.noTitle}
             </button>
             {props.ismaybe?<button type="button" className={props.value==3?"prefferedBtnsSelected":"dashboardCurrentStatusBtn"} onClick={()=>props.onSelected(3)}>
                {props.maybeTitle}
                </button>:null} 
           </div>
  );
  }
  
  export default ButtonSwitch;
  


            