import React from "react"
import "./Main.scss"
import FirstSection from "../FirstSection/FirstSection"
import SecondSection from "../SecondSection/SecondSection"
import ThirdSection from "../ThirdSection/ThirdSection"
import FourthSection from "../FourthSection/FourthSection"
import FifthSection from "../FifthSection/FifthSection"
import SixSection from "../SixSection/SixSection"
import SeventhSection from "../SeventhSection/SeventhSection"
import EightSection from "../EightSection/EightSection"

function Main() {
  return (
    <main>
      <div className="innerMain">
        <FirstSection />
        <SecondSection />
        <ThirdSection />
        <FourthSection />
        <FifthSection />
        <SixSection />
        <SeventhSection />
        <EightSection />
      </div>
    </main>
  )
}

export default Main
