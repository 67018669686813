   
   
import React, { useState, useEffect  } from 'react';
import radio_checked from "../assets/radio_button_checked.svg"
import radio_unchecked from "../assets/radio_button_unchecked.svg"
import checked from "../assets/check_box.svg";
import unchecked from "../assets/check_box_outline_blank.svg";

const RadioOption = (props) => {
    //const trans =props.route.params.obj;


  return (
    <div className="option  plusIcon" onClick={props.onClick}>
        {props.type=='radio'?<img src={props.checked?radio_checked:radio_unchecked} alt=""  className="plusIcon" />:<img src={props.checked?checked:unchecked} alt=""  className="plusIcon" />}
        
        <label className="optLabl">
        {props.title}
        </label>
    </div>
  );
  }
  
  export default RadioOption;
  