import React, { useRef } from "react"
import "./FifthSection.scss"
import angleDown from "../assets/angle-down.svg"
import angleLeft from "../assets/angle-left.svg"
import angleRight from "../assets/angle-right.svg"
import image from "../assets/pexels-tima-miroshnichenko-8376258.jpg"
import fifthSecData from "./FifthSecData"

function FifthSection() {
  const carousel = useRef(null)

  const handleLeftClick = (e) => {
    e.preventDefault()
    carousel.current.scrollLeft -= carousel.current.offsetWidth
  }

  const handleRightClick = (e) => {
    e.preventDefault()
    carousel.current.scrollLeft += carousel.current.offsetWidth
  }

  return (
    <section>
      <div className="innerFifthSection">
        <h1 className="testimonials">
          Hear from our partners
          <img src={angleDown} alt="" className="angleDown" />
        </h1>

        <div className="testimonialSlideWrap" ref={carousel}>
          {fifthSecData.map((item) => (
            <div className="testimoy" key={item.id}>
              <div className="testimonyPixandName">
                <img src={image} alt="" className="testimonyPicture" />
                <p className="bizName">{item.BizName}</p>
              </div>
              <p className="testimonyText">{item.BizTestimony}</p>
              <p className="readCaseStudy"> Read Case Study</p>
            </div>
          ))}
        </div>

        <div className="slideBtn">
          <img
            src={angleLeft}
            alt=""
            className="angleLeft"
            onClick={handleLeftClick}
          />
          <img
            src={angleRight}
            alt=""
            className="angleRight"
            onClick={handleRightClick}
          />
        </div>
      </div>
    </section>
  )
}

export default FifthSection
