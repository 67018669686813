import "./CheckupDashBoard.scss"
import React from "react"
import DashboardHeader from "../headers/DashboardHeader"
import fingerPrint from "../assets/fingerprint.svg"
import InitialAssessmentsData from "./InitialAssessmentsData"
import AdditionalAssessmentData from "./AdditionalAssessmentsData"
import AboutClientData from "./AboutClientData"

function CheckupDashBoard() {
  return (
    <>
      <DashboardHeader />
      <div className="CheckupDashBoard">
        <div className="innerCheckupDashBoard">
          <div className="checkupWrapList">
            <div className="clientCheckupTitleAndParagraph">
              <div className="clientDashBoardTitleHeadText">
                <p className="clientDashBoardTItleHeadP">About Adams</p>
              </div>
              <p className="clientDashBoardParagraph">
                capture information about Adams, as well as their likes and
                preferences,
              </p>
            </div>

            <div className="CheckupListWrap">
              <div className="checkupList">
                {AboutClientData.map((item) => (
                  <div className="checkListsDbrd">
                    <img src={fingerPrint} alt="" className="listImage" />
                    <div className="clientCheckupListTitle">
                      <p className="listTitleHead">{item.listTitleHead}</p>
                      <p className="listTitleDesc">{item.listTitleDesc}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="checkupWrapList">
            <div className="clientCheckupTitleAndParagraph">
              <div className="clientDashBoardTitleHeadText">
                <p className="clientDashBoardTItleHeadP">Initial assessments</p>
              </div>
              <p className="clientDashBoardParagraph">
                Carry out a holistic initial assessment across eight key areas
                of care.
              </p>
            </div>

            <div className="CheckupListWrap">
              <div className="checkupList">
                {InitialAssessmentsData.map((item) => (
                  <div className="checkListsDbrd">
                    <img src={fingerPrint} alt="" className="listImage" />
                    <div className="clientCheckupListTitle">
                      <p className="listTitleHead">{item.listTitleHead}</p>
                      <p className="listTitleDesc">{item.listTitleDesc}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="checkupWrapList">
            <div className="clientCheckupTitleAndParagraph">
              <div className="clientDashBoardTitleHeadText">
                <p className="clientDashBoardTItleHeadP">
                  Additional assessments
                </p>
              </div>
              <p className="clientDashBoardParagraph">
                Select additional assessments that are relevant to Adam's needs
                and potential risks.
              </p>
            </div>

            <div className="CheckupListWrap">
              <div className="checkupList">
                {AdditionalAssessmentData.map((item) => (
                  <div className="checkListsDbrd">
                    <img src={fingerPrint} alt="" className="listImage" />
                    <div className="clientCheckupListTitle">
                      <p className="listTitleHead">{item.listTitleHead}</p>
                      <p className="listTitleDesc">{item.listTitleDesc}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckupDashBoard
