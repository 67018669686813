import React, { useState } from "react"
import "./EightSection.scss"
import logo from "../assets/wecareLogo.png"
import image from "../assets/arrow-down.svg"
import { Link } from "react-router-dom"
import dLoadBtn from "../assets/image14.png"
import dLoadBtn2 from "../assets/image15.png"
// import eightSecData from "./EightSecData"

function EightSection() {
  const [btnState, setBtnState] = useState(true)
  const [btnState1, setBtnState1] = useState(true)
  const [btnState2, setBtnState2] = useState(true)
  const [btnState3, setBtnState3] = useState(true)
  const [btnState4, setBtnState4] = useState(true)

  const handleExpandHeight = () => {
    setBtnState((btnState) => !btnState)
  }
  const handleExpandHeight1 = () => {
    setBtnState1((btnState) => !btnState)
  }

  const handleExpandHeight2 = () => {
    setBtnState2((btnState) => !btnState)
  }
  const handleExpandHeight3 = () => {
    setBtnState3((btnState) => !btnState)
  }
  const handleExpandHeight4 = () => {
    setBtnState4((btnState) => !btnState)
  }

  // let toggleClass = btnState ? "nonActive" : null
  /* 
  const expandHeight = useRef()

  const handleExpandHeightClick = () => {
    expandHeight.current.
  } */

  return (
    <footer>
      <div className="innerEightSection">
        <div className="logoAndSupport">
          <img src={logo} alt="" className="footerLogo" />
          <p className="supportText">support@We-Care.care</p>
        </div>
        <div className="listFoots">
          <div className="footerList">
            <div className="footListHeads">
              <p className="listHeadText">our products</p>
              <img
                src={image}
                onClick={handleExpandHeight}
                alt=""
                className="footArrowDown"
              />
            </div>

            {btnState && (
              <ul className="footListing">
                <li className="footListed">
                  <Link className="ourProdLinks">Care Management</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">People & Operations</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">Auditing</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">Connecting</Link>
                </li>
              </ul>
            )}
          </div>
          <div className="footerList">
            <div className="footListHeads">
              <p className="listHeadText">Resources</p>
              <img
                src={image}
                onClick={handleExpandHeight1}
                alt=""
                className="footArrowDown"
              />
            </div>

            {btnState1 && (
              <ul className="footListing">
                <li className="footListed">
                  <Link className="ourProdLinks">Webinars and workshops</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">Industry and community</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">events</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">Cost savings calculator</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">Refer a new partner</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">The We-Care blog</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">CQC hub</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">
                    Recruitment and retention hub
                  </Link>
                </li>
              </ul>
            )}
          </div>

          <div className="footerList">
            <div className="footListHeads">
              <p className="listHeadText">Company</p>
              <img
                src={image}
                onClick={handleExpandHeight2}
                alt=""
                className="footArrowDown"
              />
            </div>

            {btnState2 && (
              <ul className="footListing">
                <li className="footListed">
                  <Link className="ourProdLinks">About We-Care</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">Careers at We-Care</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">We-Care in the news</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">Security and data</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">protection</Link>
                </li>
              </ul>
            )}
          </div>
          <div className="footerList">
            <div className="footListHeads">
              <p className="listHeadText">Follow us</p>
              <img
                src={image}
                onClick={handleExpandHeight3}
                alt=""
                className="footArrowDown"
              />
            </div>

            {btnState3 && (
              <ul className="footListing">
                <li className="footListed">
                  <Link className="ourProdLinks">Facebook</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">Twitter</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">LinkedIn</Link>
                </li>
              </ul>
            )}
          </div>
          <div className="footerList">
            <div className="footListHeads">
              <p className="listHeadText">Terms and Conditions</p>
              <img
                src={image}
                onClick={handleExpandHeight4}
                alt=""
                className="footArrowDown"
              />
            </div>

            {btnState4 && (
              <ul className="footListing">
                <li className="footListed">
                  <Link className="ourProdLinks">Privacy policy</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">Cookie policy</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">Certified BCorp</Link>
                </li>
                <li className="footListed">
                  <Link className="ourProdLinks">We-Care en Español 🇪🇸</Link>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className="getAppWrap">
          <h1 className="getItOn">GET THE APP ON</h1>
          <div className="downloadLink">
            <Link>
              <img src={dLoadBtn} alt="" className="downloadBtn" />
            </Link>
            <Link>
              <img src={dLoadBtn2} alt="" className="downloadBtn2" />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default EightSection
