import React from "react";
import { createRoot } from "react-dom/client";
import { Outlet, useRoutes } from 'react-router-dom';

import "./App.scss"
import Header from "./Header/Header"
import Main from "./Main/Main"
import Login from "./LoginPage/Login"
import Dashboard from "./views/Dashboard"
import ClientBoard from "./views/ClientBoards/ClientBoard"
import WeCareMessage from "./WeCareMessageForm/WeCareMessage"
import CheckupDashBoard from "./CheckupDashBoard/CheckupDashBoard"
import EightSection from "./EightSection/EightSection";
import FourthSection from "./FourthSection/FourthSection";
import FifthSection from "./FifthSection/FifthSection";
import PersonalCare from "./views/careplan/personalcare";

import ClientCare from "./views/client_care";


function App() {
  {/* return (
    <div className="App">
      <Header />
      <Main />  */}

      {/*<Login /> 
      <Dashboard />
       <ClientBoard /> */}
      {/* <WeCareMessage /> */}
      {/* <CheckupDashBoard /> 
    </div>

  )*/}
  const routes = useRoutes([
    {
      path: '/',
      element: <Login />
    },
    {
      path: 'clients',
      element: <ClientBoard />,
      children: [
        
        {
          path: ':id',
          element: <Dashboard />
        },]
    },
    {
      path: 'dashboard',
      element: <Dashboard />,
      
    },
    {
      path: 'message',
      element: <WeCareMessage />,
      
    },
    {
      path: 'checkup',
      element: <CheckupDashBoard />,
      
    },
    {
      path: 'main',
      element: <Main />,
      
    },
    {
      path: 'clientcare',
      element: <ClientCare />,
      
    },
    {
      path: 'personalcare',
      element: <PersonalCare />,
      
    },
    {
      path: 'eight',
      element: <EightSection />,
      
    }
  ]);
  return routes;
}

export default App
