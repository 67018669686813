import React, { useRef, useState } from "react"
import "./SecondSection.scss"
import secondSecData from "./secondSecData"
import arrowLeftNav from "../assets/arrow-left-long.svg"
import arrowRightNav from "../assets/arrow-right-long.svg"

function SecondSection() {
  const [secondSectionData, setSecondSectionData] = useState(secondSecData)
  // const [newWidth, setNewidth] = useState()

  const carousel = useRef(null)

  const handleLeftClick = (e) => {
    e.preventDefault()
    carousel.current.scrollLeft -= carousel.current.offsetWidth
  }

  const handleRightClick = (e) => {
    e.preventDefault()
    carousel.current.scrollLeft += carousel.current.offsetWidth
  }

  const widthChange = (id) => {
    // const newData = secondSecData.map((item) => (item.width = 20))
    // setSecondSectionData(newData)
    const newWidth = secondSectionData.map((item) =>
      item.id === id
        ? { ...item, width: 90 }
        : item !== id
        ? { ...item, width: 20 }
        : item
    )
    setSecondSectionData(newWidth)
  }

  // const mouseMove = () => {
  //   if (!newWidth) return
  //   widthChange(newWidth.id)
  //   setNewidth(" ")
  // }

  return (
    <section>
      <div className="innerSecondSection">
        <h1 className="secondHeadText">
          Excellent platform, <span className="dBlk">functions optimally</span>{" "}
          as one.
        </h1>

        <div className="secondSectionSlides" ref={carousel}>
          {secondSectionData.map((item) => (
            <div
              className="slideWrap"
              key={item.id}
              style={{
                width: `${item.width}%`,
                backgroundImage: `url(${item.Bg})`,
              }}
              onMouseEnter={() => widthChange(item.id)}
            >
              <div className="innerSlideWrap">
                <h1 className="slideText">{item.slideText}</h1>
              </div>
            </div>
          ))}
        </div>

        <div className="SecondSecNavigate">
          <img
            src={arrowLeftNav}
            alt=""
            className="arrowLeftNav navig"
            onClick={handleLeftClick}
          />
          <img
            src={arrowRightNav}
            alt=""
            className="arrowRightNav navig"
            onClick={handleRightClick}
          />
        </div>
      </div>
    </section>
  )
}

export default SecondSection
