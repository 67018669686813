import "./WeCareMessageHead.scss"
import React, { useRef } from "react"
import { Link } from "react-router-dom"
import logo from "../assets/wecareLogo.png"

function WeCareMessageHead() {
  const navRef = useRef()
  const menuMesRef = useRef()

  const showNavbar = () => {
    navRef.current.classList.toggle("displayNone")
    menuMesRef.current.classList.toggle("closeClientMessNav")
  }

  return (
    <div className="WeCareMessageHead">
      <div className="innerWeCareMessageHead">
        <img src={logo} alt="" className="weCareLogoImg" ref={navRef} />
        <nav ref={navRef} className="weCareMessageNav displayNone">
          <ul className="weCareMessageList">
            <li className="weCareLink">
              <Link className="weCareLnk">Our Product</Link>
            </li>
            <li className="weCareLink">
              <Link className="weCareLnk">Why We-Care</Link>
            </li>
            <li className="weCareLink">
              <Link className="weCareLnk">Resources</Link>
            </li>
            <li className="weCareLink">
              <Link className="weCareLnk">Sign In</Link>
            </li>
          </ul>

          <div className="weCareBook">
            <button type=" button" className="weCareMessageNavBtn">
              Book a Demo
            </button>
          </div>
        </nav>

        <div ref={menuMesRef} className="weCareMessMenu " onClick={showNavbar}>
          <div className="weCareMessline"></div>
          <div className="weCareMessline midMess"></div>
          <div className="weCareMessline"></div>
        </div>
      </div>
    </div>
  )
}

export default WeCareMessageHead
