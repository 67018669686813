import React from "react"
import "./FirstSection.scss"
import arrowDown from "../assets/angles-down.svg"
import assoImg1 from "../assets/AssociateLogos/image5.png"
import assoImg2 from "../assets/AssociateLogos/image4.png"
import assoImg3 from "../assets/AssociateLogos/image1.png"
import assoImg4 from "../assets/AssociateLogos/image2.png"
import assoImg5 from "../assets/AssociateLogos/image3.png"

function FirstSection() {
  return (
    <section>
      <div className="innerFirstSection">
        <div className="SectionHeads">
          <h1 className="sectionHeadText">
            The Technology
            <span className="partner">
              Partner for <span className="homeCareColor">Homecare</span>
            </span>
          </h1>

          <p className="sectionHeadParagraph">
            A simple, all-encompassing home
            <span className="brr">
              healthcare technology that encourages team motivation
            </span>
            and promotes growth.
          </p>

          <button className="bookFreeDemo">Book a free demo</button>

          <div className="inAssociation">
            <p className="inAssociate">In association:</p>
            <div className="associateGroup">
              <img src={assoImg1} className="assoImg" alt="associateLogo" />
              <img src={assoImg2} className="assoImg" alt="associateLogo" />
              <img src={assoImg3} className="assoImg" alt="associateLogo" />
              <img src={assoImg4} className="assoImg" alt="associateLogo" />
              <img src={assoImg5} className="assoImg" alt="associateLogo" />
            </div>
          </div>
        </div>
        <div className="SectionPicture"></div>
        <div className="SectionLine">
          <div className="line"></div>
          <div className="circle">
            <img src={arrowDown} className="arrowDown" alt="arrowDown" />
            {/*  <div className="arrow">
              <div className="arrowLine"></div>
              <div className="arrowLine"></div>
            </div> */}
          </div>
          <div className="line"></div>
        </div>
      </div>
    </section>
  )
}

export default FirstSection
