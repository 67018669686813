import "./Login.scss"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import weCareLogo from "../assets/wecareLogo.png"
import hide from "../assets/eye-slash.svg"
import show from "../assets/eye.svg"
import { useNavigate } from "react-router-dom";
import * as Constants from "../Constants"

const Login =(props)=> {
  const [showPw, setShowPw] = useState(true)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [companyid, setCompayId] = useState(1)
  const [loading, setLoading] = useState(false);
  const [errortext, setErrortext] = useState('');
  const [isPassword, setIsPassword]=useState(true);
  const navigate = useNavigate();

  // const

  const handleShowPw = () => {
    setShowPw((prev) => !prev)
  }

  const onLogin =() =>{
    var data={email,password,companyid}
    Constants.postData("api/carer/login", data,{}).then(async (res)=>{
     
      console.log(res);
      setLoading(false);

      if (res.code === 0) {
         
        navigate("clients",{state:{carercom:res.carer}});
        console.log(
          'Registration Successful. Please Login to proceed'
        );

       

      } else {
        setErrortext(res.status);
      }
  }).catch((e)=>{
    console.log(e)
    setLoading(false);
    setErrortext("Network Issues with registration. Try again");
  });
    

  }

  const reveal = showPw ? show : hide

  return (
    <div className="loginWrap">
      <div className="login">
        <div className="loginTexts">
          <img src={weCareLogo} alt="" className="weCareFormLogo" />
          <h1 className="logInHeadText">Log in to your Account</h1>
          <p className="welcomeBack">Welcome! we care always</p>
        </div>
        <form className="weCareForm">
          <div>
            <label htmlFor="" className="email weCareLabel">
              Email
            </label>
            <input
              autoFocus
              type="email"
              value={email} onChange={(e)=> setEmail(e.target.value)}
              className="emailInput weCareInput"
              required
              placeholder="Email"
            />
            <label htmlFor="" className="password weCareLabel">
              Password
            </label>
            <div className="pw">
              <input
                
                type={!showPw ? "text" : "password"}
                className="passwordInput weCareInput"
                required
                placeholder="Password"
                value={password} onChange={(e)=> setPassword(e.target.value)}
              />
              <span className="revealPW">
                <img
                  src={reveal}
                  alt=""
                  className="reveal"
                  onClick={handleShowPw}
                />
              </span>
            </div>
           {/*  <label htmlFor="" className="companyCode weCareLabel">
              Company Code
            </label>

            <input
              autoFocus
              type="text"
              className="companyCodeInput weCareInput"
              required
              placeholder="Company Code"
            /> */}

            <div>
              <span className="holdTight">
                <input
                  autoFocus
                  type="checkbox"
                  className="rememberMe"
                  id="rememberMe"
                />
                <label htmlFor="rememberMe" className="rememberMe">
                  Remember me
                </label>
              </span>
              <p className="forgotPW">
                <Link> Forgot Password?</Link>
              </p>
            </div>

            <div className="btnWrapp">
              <button type="button" className="loginSubmit" onClick={()=>onLogin()}>
                Log In
              </button>
            </div>
            <div className="registerANew">
              <p className="dontHaveAcc">
                Don't have account? <Link> Create an Account</Link>
              </p>
            </div>
          </div>
        </form>
      </div>
      <div className="logInSidePicture">
        <div className="innerLoginSide">
          <div className="weCareAllTimes">
            <p className="weCareAl">
              We care <span className="logBrk"></span> at all times
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
