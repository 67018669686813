import "./WeCareMessageBody.scss"
import React from "react"

function WeCareMessageBody() {
  return (
    <div className="WeCareMessageBody">
      <div className="innerWeCareMessageBody">
        <h1 className="soundsLike">
          sounds like We Care is the right fit for you, we would be delighted to
          discuss further.
        </h1>

        <form action="" className="weCareForm">
          <div className="weCareLabelAndInput">
            <label htmlFor="" className="weCareMessageLabel">
              First Name
            </label>
            <input
              autoFocus
              type="text"
              className="weCareMessageInput"
              placeholder="First Name"
            />
          </div>
          <div className="weCareLabelAndInput">
            <label htmlFor="" className="weCareMessageLabel">
              Last Name
            </label>
            <input
              autoFocus
              type="text"
              className="weCareMessageInput"
              placeholder="Last Name"
            />
          </div>
          <div className="weCareLabelAndInput">
            <label htmlFor="" className="weCareMessageLabel">
              Company Name
            </label>
            <input
              autoFocus
              type="text"
              className="weCareMessageInput"
              placeholder="Company Name"
            />
          </div>
          <div className="weCareLabelAndInput">
            <label htmlFor="" className="weCareMessageLabel">
              Phone Number
            </label>
            <input
              autoFocus
              type="text"
              className="weCareMessageInput"
              placeholder="Phone Number"
            />
          </div>
          <div className="weCareLabelAndInput">
            <label htmlFor="" className="weCareMessageLabel">
              Seniority
            </label>
            <select className="weCareMessageInput">
              <option value="">Seniority</option>
            </select>
          </div>
          <div className="weCareLabelAndInput">
            <label htmlFor="" className="weCareMessageLabel">
              Region
            </label>
            <select className="weCareMessageInput">
              <option value="">Region</option>
            </select>
          </div>
          <div className="weCareLabelAndInput">
            <label htmlFor="" className="weCareMessageLabel">
              My Organisation is a
            </label>
            <select className="weCareMessageInput">
              <option value="">My organisation is a</option>
            </select>
          </div>
          <div className="weCareLabelAndInput">
            <label htmlFor="" className="weCareMessageLabel">
              How many clients do you have?
            </label>
            <input
              autoFocus
              type="text"
              className="weCareMessageInput"
              placeholder="How many clients do you have?"
            />
          </div>
          <div className="weCareLabelAndInput">
            <label htmlFor="" className="weCareMessageLabel">
              Are you CQC/Care Inspectorate registered?
            </label>
            <select className="weCareMessageInput">
              <option value="">
                Are you CQC/Care Inspectorate registered?
              </option>
            </select>
          </div>
          <div className="weCareLabelAndInput">
            <label htmlFor="" className="weCareMessageLabel">
              How did you first hear about We-Care
            </label>
            <select className="weCareMessageInput">
              <option value="">How did you first hear about We-Care</option>
            </select>
          </div>

          <p className="weCareMessageDesc">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam fuga
            id, ratione sint voluptatem deleniti? Reprehenderit beatae fugit
            similique numquam aliquid tenetur, totam explicabo labore nesciunt
            excepturi laboriosam reiciendis in.
          </p>

          <button className="weCareMessageBtn">submit</button>
        </form>
      </div>
    </div>
  )
}

export default WeCareMessageBody
