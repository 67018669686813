import React from "react"
import "./ThirdSection.scss"
import thirdSecData from "./ThirdSecData"

function ThirdSection() {
  return (
    <section>
      <div className="innerThirdSection">
        <h1 className="partnersText">partners</h1>
        <p className="partnerParagraph">
          We partner with care businesses of every size.
        </p>

        <div className="partnerLogoWrap">
          {thirdSecData.map((item) => (
            <div className="logoWrap" key={item.id}>
              <img className="thirdSecLogo" src={item.logo} alt="" />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ThirdSection
